import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Card, Spin, Radio, Form, Select, Anchor} from 'antd'
import { AppContext } from '../App';

import { DownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx'


import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Colors,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
  ChartDataLabels,
  ArcElement, 
  Tooltip, 
  Legend,
  Colors,
);

const IncomeThisWeek = (homeData) => {

    const token = localStorage.getItem('Authentication');
    const API_URL = process.env.REACT_APP_API_URL;

    const [maxValueOfChart, setMaxValueOfChart] = useState(100)
    const [loadingChart, setLoadingChart] = useState(true)
    const [chartReady, setChartReady] = useState(false)
    const [chartType, setChartType] = useState("week")
    const [averageIncome, setAverageIncome] = useState(5000)
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const [branch, setBranch] = useState([]);
    const [branchSelected, setBranchSelected] = useState('all');

    const [listDateData,setListDateData] = useState([])
    const [listIncomeData,setListIncomeData] = useState([])

    const [form] = Form.useForm();

    // period change
     useEffect(() => {
      
      setChartType(homeData.chartType)
      setChartData({
        labels: [],
        datasets: [],
      })

      FetchListSpenderRange(homeData.chartType, branchSelected)

    },[homeData.chartType]);

    // branch change
    useEffect(() => {

      setBranchSelected(homeData.branchSelectedIndex)
      setLoadingChart(true)
      setChartReady(false)
      setChartData({
        labels: [],
        datasets: [],
      })
      FetchListSpenderRange(chartType,homeData.branchSelectedIndex)

  }, [homeData.branchSelectedIndex])

    const options = {
        plugins: {
          legend: false,
          datalabels: {
            anchor:'end',
            align:'end',
            color: '#000',
          },
          tooltip: {
           callbacks:{
            title: (items) => {
              const item = items[0]
              var label = item.label
              var minValue = parseInt(label) - 200
              if(label == "1000+"){
                return label
              }else{
                minValue++
                return minValue+" - "+label;
              }
            },
           }
          }
        },
        responsive: true,
        scales: {
         y:{
          beginAtZero: true,
          max: maxValueOfChart
         },
        }
    };


   const FetchListSpenderRange = async (chartTypeData,branchId) => {

      if(!branchId){branchId = ""}

        try {
            const response = await axios.get(`${API_URL}listSpenderRange/${chartTypeData}/${branchId}`, {
              headers: {
                'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })

            const listDate = response.data.listRange
            const listIncome = response.data.listTopSpender
            var maxValueOfChart = parseInt(response.data.maxValueOfChart)
            if( maxValueOfChart <= 10 ){
              maxValueOfChart = maxValueOfChart+1
            }else if(maxValueOfChart <= 100 ){
              var maxValueOfChart = response.data.maxValueOfChart + 10
            }else if(maxValueOfChart > 100 ){
              var maxValueOfChart = response.data.maxValueOfChart + 100
            }


            setListDateData(listDate)
            setListIncomeData(listIncome)
            setMaxValueOfChart(maxValueOfChart)


            const data = {
                labels: listDate,
                datasets: [{
                  data: listIncome.map((x) => x.count ? x.count : null),
                  barPercentage: 1,
                  categoryPercentage: 1,
                }],
         
             };

            setChartData(data);

            setLoadingChart(false)
            setChartReady(true)

          } catch (error) {
            console.log('error', error)
          }
    }

    const downloadData = async () =>{

      var incomeRecord;
      const incomeData = []
  
      const headerIncome = {
        spenderRange: "จำนวนเงินที่ใช้จ่าย",
        count: "จำนวนลูกค้า",
      }
  
      incomeData.push(headerIncome)
  
      for(let index = 0 ; index < listDateData.length ; index++){
        incomeRecord = {
          spenderRange: listDateData[index],
          count: listIncomeData[index].count,
        }
  
        incomeData.push(incomeRecord)
      }
  
      // add width
      var wscols = []
      for(let indexWidth = 0 ; indexWidth < 2 ; indexWidth++){
        wscols.push({wch:20})
      }
  
      const workbook = XLSX.utils.book_new()
      const worksheetIncome = XLSX.utils.json_to_sheet(incomeData, {skipHeader:true})
      worksheetIncome['!cols'] = wscols;
  
  
      XLSX.utils.book_append_sheet(workbook, worksheetIncome, 'จำนวนเงินที่ใช้จ่าย')
      XLSX.writeFile(workbook, 'จำนวนเงินที่ใช้จ่าย.xlsx')
  
    }


    return (
        <Card title="จำนวนเงินที่ใช้จ่าย" extra={<DownloadOutlined onClick={()=>downloadData()}/>}>
            <center> <Spin spinning={loadingChart} /></center>
            {chartReady && <Bar options={options} data={chartData} /> }
        </Card>
    )
}

export default IncomeThisWeek