import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Input, Space, Button, message, Tag, Modal, Image, Spin, Form, Select} from 'antd'
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';


import { AppContext } from '../App';



const ListMachine = () => {
  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: Math.floor(window.screen.height/125),
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [onCheckTransaction, setOnCheckTransaction] = useState(false);
  const [openModalQrcode, setOpenModalQrcode] = useState(false)
  const [openModalController, setOpenModalController] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [modalControllerContent, setModalControllerContent] = useState('')
  const [machineSelected,setMachineSelected] = useState('เครื่องหมายเลข')

  const [form] = Form.useForm();
  const [formBranch] = Form.useForm();
  const [commandSelected, setCommandSelected] = useState('')
  const [machineTime, setMachineTime] = useState(25)
  const [branch, setBranch] = useState([]);
  const [branchSelected, setBranchSelected] = useState('')

  const [loadingStatus, setLoadingStatus] = useState(true)
  const [loadingControl, setloadingControl] = useState(false)

  

  const [messageApi, contextHolder] = message.useMessage();

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
  };



  const getColumnSearchProps = (dataIndex) => ({

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
          style={{
          padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
      >
          <Input
          ref={searchInput}
          placeholder={`ค้นหารหัสเครื่อง`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
              marginBottom: 8,
              display: 'block',
          }}
          />
          <Space>
          <Button
              type="primary"
              icon={<SearchOutlined />}
              size="small"
              style={{
              width: 120,
              }}
              onClick={() => {
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
          >
              Search
          </Button>
          <Button
              onClick={() => {
                  clearFilters
                  handleReset(clearFilters)
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
              size="small"
              style={{
              width: 50,
              }}
          >
              Reset
          </Button>
          </Space>
      </div>
      ),
      filterIcon: (filtered) => (
      <SearchOutlined
          style={{
          color: filtered ? '#000000' : undefined,
          }}
      />
      ),
      onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
      if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
      }
      },
  });
  
  const columns = [
    {
        title: 'หมายเลขเครื่อง',
        width: 20,
        dataIndex: 'machineNo',
        key: 'machineNo',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text}
          </div>
        ),
    },
    {
      title: 'รหัสเครื่อง',
      width: 50,
      dataIndex: 'machineCode',
      key: 'machineCode',
      // fixed: 'left',
      ...getColumnSearchProps('machineCode'),
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }}>
          {text}
        </div>
      )    
    },
    {
        title: 'ชื่อเครื่อง',
        width: 100,
        dataIndex: 'machineName',
        key: 'machineName',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text}
          </div>
        ),
    },
    {
      title: 'ประเภท',
      width: 50,
      dataIndex: 'machineType',
      key: 'machineType',
      // fixed: 'left',
      hidden: true,
      render: (text, record) => (
        <div>
          {text === 'washer' ? 'เครื่องซัก' : (text === 'dryer' ? 'เครื่องอบ' : 'เครื่องซักอบ')}
        </div>
      ),
  },
    {
      title: 'คำสั่ง',
      width: 100,
      dataIndex: 'command',
      key: 'command',
      // fixed: 'left',
      render: (text, record) => (
        <div>
          {text === 'start' ? 'เริ่มทำงาน '+(record.programName ? '('+record.programName+')' : '') : (text === 'stop' ? 'หยุดทำงาน' : (text === 'extendTime' ? 'ต่อเวลาอบผ้า' : (text === 'rapidAdvanceToReady' ? 'ยกเลิกการซัก' : (text === 'turnOn' ? 'เปิดเครื่อง' : text))))}
        </div>
      ),
    },
    {
        title: 'เวลาทำงาน',
        width: 50,
        dataIndex: 'machineTime',
        key: 'machineTime',
        render: (text, record) => (
          <div>
             { text }
          </div>
        ),
    },
    {
      title: 'ผู้สั่ง',
      width: 20,
      dataIndex: 'userName',
      key: 'userName',
      render: (text, record) => (
        <div>
           {text}
        </div>
      ),
  },
  {
        title: 'วันที่',
        width: 50,
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        render: (text, record) => (
          <div>
            {moment.tz(moment(text), 'Asia/Bangkok').format('DD/MM/YYYY H:mm')}
          </div>
        ),
    }
  ]

  const fetchData = async (branchId) => {
    if(!branchId){ branchId = ""}
    try {
      const response = await axios.get(`${API_URL}getMachineControlHistory/${branchId}`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: pagination.current,
          pageSize: pagination.pageSize,
        },
      })

      const adjustedData = response.data.map((history) => ({
        ...history,
        key: history.id,
      }));

      setData(adjustedData)
      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          total: response.total,
          pageSize: Math.floor(window.screen.height / 100),
        };
      });

      form.setFieldsValue({
        command: '',
        machineTime: 25,
      });

      setLoadingStatus(false)

    } catch (error) {
      console.log('error', error)
    }
  }

  const getBranch = async () => {
    try {
        const response = await axios.get(`${API_URL}getBranchManager`,
            {
                headers: { 'Authorization': `bearer ${token}` }
            })
        setBranch(response.data);
        if(response.data.length !== 0){
          formBranch.setFieldsValue({
            branch: response.data[0]? response.data[0].id : [],
        });
        }

    } catch (error) {
        console.log(error);
    }
  }

  const changeBranchMachine = async (value) => {
    setLoadingStatus(true)
    setData('')
    setBranchSelected(value)
    fetchData(value)
  }


  useEffect(() => {
    if(data.length === 0 && branch.length === 0){
      getBranch()
      fetchData()
    }
  }, [data])

  return (
    <div className="fetch-event-table">
      {
        <Form form={formBranch} >
        <Form.Item name="branch" label='สาขา' className='formItemWithNoMargin'>
        <Select style={{width:150}} optionFilterProp="label" onChange={changeBranchMachine}>
            {branch.map(branchItem => (
                <Select.Option key={branchItem.id} value={branchItem.id} label={branchItem.name}>{branchItem.name}</Select.Option>
            ))}
        </Select>
        </Form.Item>
        </Form>
      }
      <br />
      {contextHolder}
      {data && data.length > 0 ? (
        <Table
          columns={columns}
          dataSource={data}
          pagination={pagination}
          onChange={(newPagination) => setPagination(newPagination)}
          scroll={{
            x: 400,
          }}
          rowKey="id"
        />
      ) : (
        loadingStatus ? <center><Spin spinning={loadingStatus}/></center> : 'ไม่มีข้อมูล'
      )}
    </div>
  )
}

export default ListMachine