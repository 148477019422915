import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Input, Space, Button, message, Tag, Layout, Row, Col, Typography, Form, Modal, DatePicker, Select, Image} from 'antd'
import { SearchOutlined,ExclamationCircleOutlined, BarcodeOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { AppContext } from '../App';
import dayjs from 'dayjs'
import QRCode from 'qrcode'

const { Header, Content } = Layout;
const { Title } = Typography;

const ListCoupon = () => {
  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])
  const [customerData, setCustomerData] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: Math.floor(window.screen.height/125),
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [onCheckTransaction, setOnCheckTransaction] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModalQrcode, setOpenModalQrcode] = useState(false)

  const [modalContent, setModalContent] = useState('')
  const [couponSelected, setCouponSelected] = useState('');
  const [messageConfirmDelete, setMessageConfirmDelete] = useState('ยืนยันการลบคูปอง');
  const [qrcodeUrl, setQrcodeUrl] = useState('')

  const [allData,setAllData] = useState([])
  const [createdData,setCreatedData] = useState([])
  const [assignedData,setAssignedData] = useState([])
  const [usedData,setUsedData] = useState([])
  const [expiredData,setExpiredData] = useState([])

  const [allTotal,setallTotal] = useState(0)
  const [createdTotal,setCreatedTotal] = useState(0)
  const [assignedTotal,setAssignedTotal] = useState(0)
  const [usedTotal,setUsedTotal] = useState(0)
  const [expiredTotal,setExpiredTotal] = useState(0)

  const [typeAll, setTypeAll] = useState('primary')
  const [typeCreated, setTypeCreated] = useState('')
  const [typeAssigned, setTypeAssign] = useState('')
  const [typeUsed, setTypeUsed] = useState('')
  const [typeExpired, setTypeExpired] = useState('')
  

  const [form] = Form.useForm();

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
          style={{
          padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
      >
          <Input
          ref={searchInput}
          placeholder={`ค้นหาคูปอง`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
              marginBottom: 8,
              display: 'block',
          }}
          />
          <Space>
          <Button
              type="primary"
              icon={<SearchOutlined />}
              size="small"
              style={{
              width: 120,
              }}
              onClick={() => {
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
          >
              Search
          </Button>
          <Button
              onClick={() => {
                  clearFilters
                  handleReset(clearFilters)
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
              size="small"
              style={{
              width: 50,
              }}
          >
              Reset
          </Button>
          </Space>
      </div>
      ),
      filterIcon: (filtered) => (
      <SearchOutlined
          style={{
          color: filtered ? '#000000' : undefined,
          }}
      />
      ),
      onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
      if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
      }
      },
  });
  
  const columns = [
    {
      title: 'รหัสคูปอง',
      width: 100,
      dataIndex: 'code',
      key: 'code',
      // fixed: 'left',
      ...getColumnSearchProps('code'),
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }}>
          {text}
        </div>
      )    
    },
    {
        title: 'ส่วนลด (บาท)',
        width: 80,
        dataIndex: 'couponValue',
        key: 'couponValue',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text}
          </div>
        ),
    },
    {
        title: 'ประเภทส่วนลด',
        width: 90,
        dataIndex: 'couponType',
        key: 'couponType',
        // fixed: 'left',
        render: (text, record) => (
          <div>
                {text === 'all' ? 'ทุกประเภท' : (text === 'washer' ? 'เครื่องซัก' : (text === 'dryer' ? 'เครื่องอบ' : 'อื่นๆ'))}
          </div>
        ),
    },
    {
        title: 'วันที่หมดอายุ',
        width: 90,
        dataIndex: 'expireDate',
        key: 'expireDate',
        render: (text, record) => (
          <div>
              { text ? moment.tz(moment(text), 'Asia/Bangkok').format('DD/MM/YYYY') : ''}
          </div>
        ),
      },
    {
      title: 'เบอร์โทรลูกค้า',
      width: 90,
      dataIndex: 'id',
      key: 'id',
      // fixed: 'left',
      render: (text, record) => (
        <div>
          {record.customer ? record.customer.phone : ''}
        </div>
      ),
    },
    {
        title: 'สถานะ',
        width: 90,
        dataIndex: 'status',
        key: 'status',
        render: (status, record) => (
          <div>
             {status === 'created' ? <Tag color='green'>สร้างแล้ว</Tag> : (status === 'assigned' ? <Tag color='blue'>กำหนดแล้ว</Tag> : (status === 'used' ? <Tag color='green'>ใช้แล้ว</Tag> : (status === 'expired' ? <Tag color='red'>หมดอายุ</Tag> : '')))}
          </div>
        ),
    },
    {
        title: 'QR Code',
        width: 90,
        dataIndex: 'code',
        key: 'code',
        render: (text, record) => (
          <div>
            { <DownloadOutlined className='buttonDownload' onClick={ () => showQrcode(record)}/>}
          </div>
        ),
    },
    {
      title: 'การจัดการ',
      key: 'action',
      // fixed: 'right',
      width: 120,
      render: (text, record) => (
        <div>
             { record.status !== 'used' ? <div><Button type='primary' onClick={()=>showEditModal(record)}>แก้ไข</Button> <Button onClick={() => showDeleteModal(record)} danger>ลบ</Button> </div>: '' }
        </div>
      ),
    },
  ]

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}listCoupon`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: pagination.current,
          pageSize: pagination.pageSize,
        },
      })

      const adjustedData = response.data.map((coupon) => ({
        ...coupon,
        key: coupon.id,
      }));

      setData(adjustedData)

      const createdData = adjustedData.filter((item) => item.status === 'created')
      const assignedData = adjustedData.filter((item) => item.status === 'assigned')
      const usedData = adjustedData.filter((item) => item.status === 'used')
      const expiredData = adjustedData.filter((item) => item.status === 'expired')

      setAllData(adjustedData)
      setCreatedData(createdData)
      setAssignedData(assignedData)
      setUsedData(usedData)
      setExpiredData(expiredData)

      setallTotal(adjustedData.length)
      setCreatedTotal(createdData.length)
      setAssignedTotal(assignedData.length)
      setUsedTotal(usedData.length)
      setExpiredTotal(expiredData.length)

      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          total: response.total,
          pageSize: Math.floor(window.screen.height / 90),
        };
      });

    } catch (error) {
      console.log('error', error)
    }
  }

  const getListCustomer = async () =>{
    try {

        const response = await axios.get(`${API_URL}getListCustomer`, {
          headers: {
            'Authorization': `bearer ${token}`,
            'Content-Type': 'application/json'
          },
        })
  
        setCustomerData(response.data)
  
      } catch (error) {
        console.log('error', error)
      }
}
  
    const addCoupon = async (values) => {

        var customerId = ''
        if(values.customerId){customerId = values.customerId;}

        var code = values.code
        var foundExist = false
        if (data.some(e => e.code == code)) {
            foundExist = true
         }

        if(foundExist){
            messageApi.open({
                type: 'error',
                content: 'ไม่สามารถเพิ่มคูปองได้ เนื่องจากรหัสคูปองซ้ำ',
            });
        }else{
            try {
                const response = await axios.post(`${API_URL}createCoupon`, {
                    code: values.code,
                    name: values.name,
                    couponValue: values.couponValue,
                    couponType: values.couponType,
                    expireDate: values.expireDate,
                    customerId: values.customerId
                }, {
                    headers: {
                        'Authorization': `bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                })
        
                if (response.status === 200) {
                    messageApi.open({
                        type: 'success',
                        content: 'เพิ่มค่าคูปองเรียบร้อย',
                    });
                    fetchData()
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const editCoupon = async (values) => {

        var customerId = ''
        if(values.customerId){customerId = values.customerId;}

        var code = values.code
        var foundExist = false
        if (data.some(e => e.code == code && e.id != values.couponId)) {
            foundExist = true
        }

        if(foundExist){
            messageApi.open({
                type: 'error',
                content: 'ไม่สามารถแก้ไขคูปองได้ เนื่องจากรหัสคูปองซ้ำ',
            });
        }else{
            try {
                const response = await axios.post(`${API_URL}editCoupon`, {
                    couponId: values.couponId,
                    code: values.code,
                    name: values.name,
                    couponValue: values.couponValue,
                    couponType: values.couponType,
                    expireDate: values.expireDate,
                    customerId: values.customerId
                }, {
                    headers: {
                        'Authorization': `bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                })
        
                if (response.status === 200) {
                    messageApi.open({
                        type: 'success',
                        content: 'แก้ไขคูปองเรียบร้อย',
                    });
                    fetchData()
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const deleteCoupon = async () => {
        try {
            const response = await axios.post(`${API_URL}deleteCoupon`, {
                couponId: couponSelected,
            },{
              headers: {
                'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })
      
            if(response.status === 200){
                messageApi.open({
                    type: 'success',
                    content: 'ลบค่าคูปองเรียบร้อย',
                });
                fetchData()
            }

          } catch (error) {
            console.log('error', error)
          }
    }

  const showCreateModal = async () => {
    setOpenAddModal(true)
  }

  const showEditModal = async (record) => {

    var customerId = ''
    if(record.customer){customerId = record.customer.id}

    form.setFieldsValue({
        couponId: record.id,
        name: record.name,
        code: record.code,
        couponType: record.couponType,
        couponValue: record.couponValue,
        expireDate: dayjs(record.expireDate),
        customerId: customerId
    });

    setOpenEditModal(true)
  }

  const showDeleteModal = async (record) => {
    setCouponSelected(record.id)
    setOpenDeleteModal(true)
  }

  const showQrcode = async (record) => {

    QRCode.toDataURL(record.code)
        .then(url => {
            setOpenModalQrcode(true)
            setModalContent(
                <div className='ModalContent'>
                    {record.code !== "" ? <Image width={300} src={url} preview={false}/> : <Spin/>}
                    <h3>รหัสส่วนลด: {record.code}</h3>
                    <h3>ชื่อส่วนลด: {record.name}</h3>
                    <h3>มูลค่า: {record.couponValue} บาท</h3>
                </div>
            )
        })
        .catch(err => {
            console.error(err)
        })    
  }

  const handleCancelQrcode = () => {
    setOpenModalQrcode(false)
  }

  const setDisplayData = function (status){
    if(status === 'all'){
      setData(allData)

      setTypeAll('primary')
      setTypeCreated('')
      setTypeAssign('')
      setTypeUsed('')
      setTypeExpired('')
    }else if(status === 'created'){
        setData(createdData)

        setTypeAll('')
        setTypeCreated('primary')
        setTypeAssign('')
        setTypeUsed('')
        setTypeExpired('')
    }else if(status === 'assigned'){
        setData(assignedData)

        setTypeAll('')
        setTypeCreated('')
        setTypeAssign('primary')
        setTypeUsed('')
        setTypeExpired('')
    }else if(status === 'used'){
        setData(usedData)

        setTypeAll('')
        setTypeCreated('')
        setTypeAssign('')
        setTypeUsed('primary')
        setTypeExpired('')
    }else if(status === 'expired'){
        setData(expiredData)

        setTypeAll('')
        setTypeCreated('')
        setTypeAssign('')
        setTypeUsed('')
        setTypeExpired('primary')
    }
  }


  useEffect(() => {
    fetchData()
    getListCustomer()
  }, [])

  return (
    <div className="fetch-event-table">
      {contextHolder}
      {
        <Modal
        title='QR Code คูปอง'
        centered
        open={openModalQrcode}
        footer={null}
        width={400}
        onCancel={handleCancelQrcode}
        >
        {modalContent}
        </Modal>
      }
      {
        <Modal
        title="เพิ่มคูปอง"
        centered
        open={openAddModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={() => {
            form
                .validateFields()
                .then((values) => {
                    addCoupon(values)
                    form.resetFields();
                    setOpenAddModal(false);
                })
                .catch((errorInfo) => {
                    console.log('Failed:', errorInfo);
                });
        }}
        onCancel={() => {
            form.resetFields();
            setOpenAddModal(false);
        }}
        width={400}
    >
        <Form
            form={form}
            layout="vertical"
        >
            <Form.Item name="code" label="รหัสคูปอง"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกรหัสคูปองให้ถูกต้อง',
                        pattern: new RegExp("(^[a-z0-9]*$)"),
                    },
                ]}>
                <Input type='text'/>
            </Form.Item>
            <Form.Item name="name" label="ชื่อคูปอง"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกชื่อลดคูปอง',
                    },
                ]}>
                <Input type='text'/>
            </Form.Item>
            <Form.Item name="couponValue" label="ส่วนลด (บาท)"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกส่วนลดคูปอง',
                    },
                ]}>
                <Input type='number'/>
            </Form.Item>
            <Form.Item name="couponType" label="ประเภทส่วนลดคูปอง"
                rules={[
                    {
                        required: true,
                        message: 'กรุณาเลือกประเภทคูปอง',
                    },
                ]}>
                <Select>
                    <Select.Option value="washer">เครื่องซัก</Select.Option>
                    <Select.Option value="dryer">เครื่องอบ</Select.Option>
                    <Select.Option value="all">ทุกประเภท</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="expireDate" label="วันที่คูปองหมดอายุ"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกวันที่คูปองหมดอายุ',
                            },
                        ]}>
                <DatePicker/>
              </Form.Item>
              <Form.Item name="customerId" label="ลูกค้า">
                <Select     
                    allowClear                   
                    showSearch
                    optionFilterProp="label"
                >
                    {customerData.map(customer => (
                        <Select.Option key={customer.id} value={customer.id} label={customer.phone}>{customer.phone}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Form>
        </Modal>
      }
      {
        <Modal
        title="แก้ไขคูปอง"
        centered
        open={openEditModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={() => {
            form
                .validateFields()
                .then((values) => {
                    editCoupon(values)
                    form.resetFields();
                    setOpenEditModal(false);
                })
                .catch((errorInfo) => {
                    console.log('Failed:', errorInfo);
                });
        }}
        onCancel={() => {
            form.resetFields();
            setOpenEditModal(false);
        }}
        width={400}
    >
        <Form
            form={form}
            layout="vertical"
        >
            <Form.Item name="couponId" hidden={true}> <Input type='hidden'/> </Form.Item>           
            <Form.Item name="code" label="รหัสคูปอง"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกรหัสคูปองให้ถูกต้อง',
                        pattern: new RegExp("(^[a-z0-9]*$)"),
                    },
                ]}>
                <Input type='text'/>
            </Form.Item>
            <Form.Item name="name" label="ชื่อคูปอง"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกชื่อลดคูปอง',
                    },
                ]}>
                <Input type='text'/>
            </Form.Item>
            <Form.Item name="couponValue" label="ส่วนลด (บาท)"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกส่วนลดคูปอง',
                    },
                ]}>
                <Input type='number'/>
            </Form.Item>
            <Form.Item name="couponType" label="ประเภทส่วนลดคูปอง"
                rules={[
                    {
                        required: true,
                        message: 'กรุณาเลือกประเภทคูปอง',
                    },
                ]}>
                <Select>
                    <Select.Option value="washer">เครื่องซัก</Select.Option>
                    <Select.Option value="dryer">เครื่องอบ</Select.Option>
                    <Select.Option value="all">ทุกประเภท</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="expireDate" label="วันที่คูปองหมดอายุ"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกวันที่คูปองหมดอายุ',
                            },
                        ]}>
                <DatePicker/>
              </Form.Item>
              <Form.Item name="customerId" label="ลูกค้า">
                <Select              
                    allowClear                             
                    showSearch
                    optionFilterProp="label"
                >
                    {customerData.map(customer => (
                        <Select.Option key={customer.id} value={customer.id} label={customer.phone}>{customer.phone}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Form>
        </Modal>
      }
       <Modal
            title={
                <>
                    <ExclamationCircleOutlined style={{ color: 'red', marginRight: '8px' }} />
                    ลบคูปอง
                </>
            }
            centered
            open={openDeleteModal}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            onOk={() => {
                deleteCoupon()
                setOpenDeleteModal(false)
            }}
            onCancel={() => {
                setOpenDeleteModal(false);
            }}
            width={300}
        >
            {messageConfirmDelete}
        </Modal>
      <Row>
            <Col span={1} />
            <Col span={20} >
                <Title level={3} ><BarcodeOutlined /> คูปอง</Title>
            </Col>
                <Title level={3} ><Col span={2}><Button type='primary' onClick={()=>showCreateModal()}>เพิ่มคูปอง</Button></Col></Title>
            <Col span={1} />
        </Row>
        <Row>
            <Col span={1} />
            <Col span={22} >
            สถานะคูปอง : <Button type={typeAll} onClick={()=> setDisplayData('all')}>ทั้งหมด ({allTotal})</Button>  &nbsp;
                        <Button type={typeCreated} onClick={()=> setDisplayData('created')}>สร้างแล้ว ({createdTotal})</Button> &nbsp;
                        <Button type={typeAssigned} onClick={()=> setDisplayData('assigned')}>กำหนดแล้ว ({assignedTotal})</Button> &nbsp;
                        <Button type={typeUsed} onClick={()=> setDisplayData('used')}>ใช้แล้ว ({usedTotal})</Button>  &nbsp;
                        <Button type={typeExpired} onClick={()=> setDisplayData('expired')}>หมดอายุ ({expiredTotal})</Button> <br /><br />


            </Col>
            <Col span={1} />
        </Row>
        <Row>
            <Col span={1} />
            <Col span={22} >
                {data && data.length > 0 ? (
                    <Table
                    columns={columns}
                    dataSource={data}
                    pagination={pagination}
                    onChange={(newPagination) => setPagination(newPagination)}
                    scroll={{
                        x: 400,
                    }}
                    rowKey="id"
                    />
                ) : (
                    <p>Loading</p>
                )}
            </Col>
            <Col span={1} />
        </Row>
    </div>
  )
}

export default ListCoupon