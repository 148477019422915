import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Card, Spin, Radio, Form, Select, Anchor} from 'antd'
import { AppContext } from '../App';

import { DownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Colors,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
  ChartDataLabels,
  ArcElement, 
  Tooltip, 
  Legend,
  Colors,
);

const IncomeThisWeek = (homeData) => {

    const token = localStorage.getItem('Authentication');
    const API_URL = process.env.REACT_APP_API_URL;

    const [width, setWidth] = useState(window.innerWidth);
    const [maxValueOfChart, setMaxValueOfChart] = useState(100)
    const [loadingChart, setLoadingChart] = useState(true)
    const [chartReady, setChartReady] = useState(false)
    const [chartType, setChartType] = useState("week")
    const [averageIncome, setAverageIncome] = useState(5000)
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const [branch, setBranch] = useState([]);
    const [branchSelected, setBranchSelected] = useState('all');

    const [listDateData,setListDateData] = useState([])
    const [listIncomeData,setListIncomeData] = useState([])

    const [form] = Form.useForm();

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    // period change
     useEffect(() => {
      
      setChartType(homeData.chartType)
      setChartData({
        labels: [],
        datasets: [],
      })

      FetchIncomeAppByHour(homeData.chartType, branchSelected)

    },[homeData.chartType]);

    // branch change
    useEffect(() => {

      setBranchSelected(homeData.branchSelectedIndex)
      setLoadingChart(true)
      setChartReady(false)
      setChartData({
        labels: [],
        datasets: [],
      })
      FetchIncomeAppByHour(chartType,homeData.branchSelectedIndex)

  }, [homeData.branchSelectedIndex])

    const options = {
        plugins: {
          legend: false,
          datalabels: {
            anchor:'end',
            align:'end',
            color: '#000',
            rotation: width < 1000 ? 270 : 0,
            font: {
              size: width < 1000 ? 8 : 12,
            },
          }
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            max: maxValueOfChart
          },
        },
    };


   const FetchIncomeAppByHour = async (chartTypeData,branchId) => {

      if(!branchId){branchId = ""}

        try {
            const response = await axios.get(`${API_URL}incomeAppByHour/${chartTypeData}/${branchId}`, {
              headers: {
                'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })

            const listDate = response.data.listHour
            const listIncome = response.data.listHourIncome
            var maxValueOfChart = parseInt(response.data.maxValueOfChart)

            if(maxValueOfChart > 10000){
              maxValueOfChart = maxValueOfChart + 5000
            }else if(maxValueOfChart > 1000){
              maxValueOfChart = maxValueOfChart + 1000
            }else{
              maxValueOfChart = maxValueOfChart + 100 
            } 


            setListDateData(listDate)
            setListIncomeData(listIncome)
            setMaxValueOfChart(maxValueOfChart)


            const data = {
                labels: listDate,
                datasets: [{
                  data: listIncome.map((x) => x.totalPercent ? x.totalIncome : null),
                  backgroundColor:["#FF1F57AA","#FF1F69AA","#FF1F7CAA","#FF1F8FAA","#FF1FA2AA","#FF1FB4AA","#FF1FC7AA","#FF1FDAAA","#FF1FECAA","#FF1FFFAA","#EC1FFFAA","#DA1FFFAA","#C71FFFAA","#B41FFFAA","#A11FFFAA","#8F1FFFAA","#7C1FFFAA","#691FFFAA","#571FFFAA","#441FFFAA","#311FFFAA","#1F1FFFAA","#1F31FFAA","#1F44FFAA"]
                }]
             };

            setChartData(data);

            setLoadingChart(false)
            setChartReady(true)

          } catch (error) {
            console.log('error', error)
          }
    }

    const downloadData = async () =>{

      var incomeRecord;
      const incomeData = []
  
      const headerIncome = {
          incomeDate: "วัน",
          incomeTotal: "รายได้ทั้งหมด",
          incomePercent: "คิดเป็นเปอร์เซ็นต์",
      }
  
      incomeData.push(headerIncome)
  
      for(let index = 0 ; index < listDateData.length ; index++){
        incomeRecord = {
          incomeDate: listDateData[index],
          incomeTotal: listIncomeData[index].totalIncome,
          incomePercent: listIncomeData[index].totalPercent,
        }
  
        incomeData.push(incomeRecord)
      }
  
      // add width
      var wscols = []
      for(let indexWidth = 0 ; indexWidth < 3 ; indexWidth++){
        wscols.push({wch:20})
      }
  
      const workbook = XLSX.utils.book_new()
      const worksheetIncome = XLSX.utils.json_to_sheet(incomeData, {skipHeader:true})
      worksheetIncome['!cols'] = wscols;
  
  
      XLSX.utils.book_append_sheet(workbook, worksheetIncome, 'รายได้ในแต่ละชั่วโมง')
      XLSX.writeFile(workbook, 'รายได้ในแต่ละชั่วโมง.xlsx')
  
    }


    return (
        <Card title="รายได้ในแต่ละชั่วโมง" extra={<DownloadOutlined onClick={()=>downloadData()} />}>
            <center> <Spin spinning={loadingChart} /></center>
            {chartReady && <Bar options={options} data={chartData}/> }
        </Card>
    )
}

export default IncomeThisWeek