import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { Table, Input, Space, Button, message, Modal, Select, Row, Col, Typography, Form} from 'antd'
import { SearchOutlined, TruckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';


const { Title } = Typography;


const FetchCustomer = () => {

  const [messageApi, contextHolder] = message.useMessage();

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [customerData, setCustomerData] = useState([])
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: Math.floor(window.screen.height/100),
  });

  
  const [hasData, setHasData] = useState(false)
  const [emptyData, setEmptyData] = useState(false)
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  
  const [form] = Form.useForm();
  const [selectCommissionPhone, SetSelectCommissionPhone] = useState('')
  const [messageConfirmDelete, setMessageConfirmDelete] = useState('ยืนยันการลบผู้รับจ้าง :');

  const [selectedComissionId, setSelectedComissionId] = useState('')
  
  

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
          style={{
          padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
      >
          <Input
          ref={searchInput}
          placeholder={`Search User`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
              marginBottom: 8,
              display: 'block',
          }}
          />
          <Space>
          <Button
              type="primary"
              icon={<SearchOutlined />}
              size="small"
              style={{
              width: 120,
              }}
              onClick={() => {
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
          >
              Search
          </Button>
          <Button
              onClick={() => {
                  clearFilters
                  handleReset(clearFilters)
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
              size="small"
              style={{
              width: 50,
              }}
          >
              Reset
          </Button>
          </Space>
      </div>
      ),
      filterIcon: (filtered) => (
      <SearchOutlined
          style={{
          color: filtered ? '#000000' : undefined,
          }}
      />
      ),
      onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
      if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
      }
      },
  });
  
  const columns = [
    {
      title: 'เบอร์โทร',
      width: 70,
      dataIndex: 'phone',
      key: 'phone',
      // fixed: 'left',
      ...getColumnSearchProps('phone'),
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }}>
          <a href={'/commissionHistory/'+record.id}>{text}</a>
        </div>
      )    
    },
    {
        title: 'ชื่อ นามสกุล',
        width: 90,
        dataIndex: 'name',
        key: 'name',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text ? text : ''}
          </div>
        ),
    },
    {
        title: 'ส่วนลด',
        width: 50,
        dataIndex: 'commissionRate',
        key: 'commissionRate',
        align: 'center',
        render: (text, record) => (
          <div>
            {text}
          </div>
        ),
    },
    {
      title: 'ประเภทส่วนลด',
      width: 90,
      dataIndex: 'commissionType',
      key: 'commissionType',
      align: 'center',
      render: (text, record) => (
        <div>
          { text === "percent" ? "%" : "บาท" }
        </div>
      ),
    },
    {
      title: 'สถานะ',
      width: 50,
      dataIndex: 'status',
      key: 'status',
      // fixed: 'left',
      render: (text, record) => (
        <div>
          {text == "active" ? <b style={{color:'green'}}>เปิดใช้งาน</b> : <b style={{color:'red'}}>ปิดใช้งาน</b>}
        </div>
      ),
    },{
      title: 'จำนวนครั้งที่ใช้งาน',
      width: 90,
      dataIndex: 'commissionUsed',
      key: 'commissionUsed',
      render: (text, record) => (
        <div>
          {text}
        </div>
      ),
    },{
        title: 'ส่วนลดสะสม (บาท)',
        width: 90,
        dataIndex: 'commissionDiscountTotal',
        key: 'commissionDiscountTotal',
        render: (text, record) => (
          <div>
            {text}
          </div>
        ),
    },{
        title: 'Action',
        width: 150,
        dataIndex: 'membershipTier',
        key: 'membershipTier',
        align: 'center',
        render: (text, record) => (
          <div>
            { <div> <a href={'/commissionHistory/'+record.id}><Button type='primary'>ดูประวัติ</Button></a> <Button type='primary' onClick={()=>showEditModal(record)}>แก้ไข</Button> <Button onClick={() => showDeleteModal(record)} danger>ลบ</Button> </div>}
          </div>
        ),
      }
  ]

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}getListCommission`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: pagination.current,
          pageSize: pagination.pageSize,
        },
      })

      const adjustedData = await response.data.map((customer) => ({
        ...customer,
        key: customer.id,
      }));


      if(adjustedData.length !== 0){
        setHasData(true)
        setData(adjustedData)
      }else{
        setEmptyData(true)
      }
            
      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          total: response.total,
          pageSize: Math.floor(window.screen.height / 100),
        };
      });
    } catch (error) {
      console.log('error', error)
    }
  }

  const getListCustomerAddCommission = async () =>{
    try {

        const response = await axios.get(`${API_URL}getListCustomerAddCommission`, {
          headers: {
            'Authorization': `bearer ${token}`,
            'Content-Type': 'application/json'
          },
        })
  
        setCustomerData(response.data)
  
      } catch (error) {
        console.log('error', error)
      }
}

  const showCreateModal = async () => {
    setOpenAddModal(true)
  }

  const showEditModal = async (record) => {

    SetSelectCommissionPhone(record.phone)
    form.setFieldsValue({
        commissionId: record.id,
        customerId: record.customerId,
        commissionType: record.commissionType,
        commissionRate: record.commissionRate,
        status: record.status,
    });

    setOpenEditModal(true)
  }

  const showDeleteModal = async (record) => {
    setSelectedComissionId(record.id)
    SetSelectCommissionPhone(record.phone)
    setOpenDeleteModal(true)
  }

  const addCommissionUser = async (values) => {
      try {
          const response = await axios.post(`${API_URL}createCommissionUser`, {
              customerId: values.customerId,
              commissionType: values.commissionType,
              commissionRate: values.commissionRate,
              status: values.status,
          }, {
              headers: {
                  'Authorization': `bearer ${token}`,
                  'Content-Type': 'application/json'
              }
          })
  
          if (response.status === 200) {
              messageApi.open({
                  type: 'success',
                  content: 'เพิ่มผู้รับจ้างเรียบร้อย',
              });
              fetchData()
          }
      } catch (error) {
          console.log(error);
      }
}

const editCommissionUser = async (values) => {
  try {
      const response = await axios.post(`${API_URL}editCommissionUser`, {
          commissionId: values.commissionId,
          commissionType: values.commissionType,
          commissionRate: values.commissionRate,
          status: values.status,
      }, {
          headers: {
              'Authorization': `bearer ${token}`,
              'Content-Type': 'application/json'
          }
      })

      if (response.status === 200) {
          messageApi.open({
              type: 'success',
              content: 'แก้ไขข้อมูลเรียบร้อย',
          });
          fetchData()
      }
  } catch (error) {
      console.log(error);
  }
}

const deleteCommissionUser = async () => {
  try {
      const response = await axios.post(`${API_URL}deleteCommissionUser`, {
          commissionId: selectedComissionId,
      }, {
          headers: {
              'Authorization': `bearer ${token}`,
              'Content-Type': 'application/json'
          }
      })

      if (response.status === 200) {
          messageApi.open({
              type: 'success',
              content: 'ลบข้อมูลเรียบร้อย',
          });
          fetchData()
      }
  } catch (error) {
      console.log(error);
  }
}



  useEffect(() => {
    getListCustomerAddCommission()
    fetchData()
  },[])

  return (
    <div className="fetch-event-table">
      {contextHolder}
      {
        <Modal
        title="เพิ่มผู้รับจ้าง"
        centered
        open={openAddModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={() => {
            form
                .validateFields()
                .then((values) => {
                    addCommissionUser(values)
                    form.resetFields();
                    setOpenAddModal(false);
                })
                .catch((errorInfo) => {
                    console.log('Failed:', errorInfo);
                });
        }}
        onCancel={() => {
            form.resetFields();
            setOpenAddModal(false);
        }}
        width={400}
    >
        <Form
            form={form}
            layout="vertical"
        >
            <Form.Item name="customerId" label="เบอร์โทร">
                <Select     
                    allowClear                   
                    showSearch
                    optionFilterProp="label"
                >
                    {customerData.map(customer => (
                        <Select.Option key={customer.id} value={customer.id} label={customer.phone}>{customer.phone}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item name="commissionType" label="ประเภทส่วนลด"
                rules={[
                    {
                        required: true,
                        message: 'กรุณาเลือกประเภทส่วนลด',
                    },
                ]}>
                <Select>
                    <Select.Option value="percent">เปอร์เซ็นต์ (%)</Select.Option>
                    <Select.Option value="fixed">คงที่ (บาท)</Select.Option>
                </Select>            
            </Form.Item>
            <Form.Item name="commissionRate" label="จำนวนส่วนลด"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกจำนวนส่วนลดให้ถูกต้อง (1 - 100)',
                        pattern: new RegExp("(^[1-9][0-9]?$|^100$)"),
                    },
                ]}>
                <Input type='text'/>
            </Form.Item>
            <Form.Item name="status" label="สถานะ"
                rules={[
                    {
                        required: true,
                        message: 'กรุณาเลือกสถานะ',
                    },
                ]}>
                <Select>
                    <Select.Option value="active">เปิดใช้งาน</Select.Option>
                    <Select.Option value="deactive">ปิดใช้งาน</Select.Option>
                </Select>
            </Form.Item>
        </Form>
        </Modal>
      }
      {
        <Modal
        title="แก้ไขข้อมูล"
        centered
        open={openEditModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={() => {
            form
                .validateFields()
                .then((values) => {
                    editCommissionUser(values)
                    form.resetFields();
                    setOpenEditModal(false);
                })
                .catch((errorInfo) => {
                    console.log('Failed:', errorInfo);
                });
        }}
        onCancel={() => {
            form.resetFields();
            setOpenEditModal(false);
        }}
        width={400}
    >
        <Form
            form={form}
            layout="vertical"
        >
            <Form.Item name="commissionId" hidden={true}> <Input type='hidden'/> </Form.Item>           
            <div>เบอร์โทร : {selectCommissionPhone} </div><br/>
            <Form.Item name="commissionType" label="ประเภทส่วนลด"
                rules={[
                    {
                        required: true,
                        message: 'กรุณาเลือกประเภทส่วนลด',
                    },
                ]}>
                <Select>
                    <Select.Option value="percent">เปอร์เซ็นต์ (%)</Select.Option>
                    <Select.Option value="fixed">คงที่ (บาท)</Select.Option>
                </Select>            
            </Form.Item>
            <Form.Item name="commissionRate" label="จำนวนส่วนลด"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกจำนวนส่วนลดให้ถูกต้อง (1 - 100)',
                        pattern: new RegExp("(^[1-9][0-9]?$|^100$)"),
                    },
                ]}>
                <Input type='text'/>
            </Form.Item>
            <Form.Item name="status" label="สถานะ"
                rules={[
                    {
                        required: true,
                        message: 'กรุณาเลือกสถานะ',
                    },
                ]}>
                <Select>
                    <Select.Option value="active">เปิดใช้งาน</Select.Option>
                    <Select.Option value="deactive">ปิดใช้งาน</Select.Option>
                </Select>
            </Form.Item>
        </Form>
        </Modal>
      }
      <Modal
            title={
                <>
                    <ExclamationCircleOutlined style={{ color: 'red', marginRight: '8px' }} />
                    ลบผู้รับจ้าง
                </>
            }
            centered
            open={openDeleteModal}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            onOk={() => {
                deleteCommissionUser()
                setOpenDeleteModal(false)
            }}
            onCancel={() => {
                setOpenDeleteModal(false);
            }}
            width={300}
        >
          <Form
            form={form}
            layout="vertical"
        >
            <Form.Item name="commissionId" hidden={true}> <Input type='hidden'/> </Form.Item>           
            {messageConfirmDelete} {selectCommissionPhone}
          </Form>
        </Modal>
      <Row>
        <Col span={1} />
        <Col span={20}>
          <Title level={3}><TruckOutlined /> รายชื่อผู้รับจ้างซักผ้า</Title>
        </Col>
        <Title level={3} ><Col span={2}><Button type='primary' onClick={()=>showCreateModal()}>เพิ่มผู้รับจ้าง</Button></Col></Title>
        <Col span={1} />

      </Row>
      <Row>
        <Col span={1} />
        <Col span={22}>

        {data && hasData ? (
          <Table
            columns={columns}
            dataSource={data}
            pagination={pagination}
            onChange={(newPagination) => setPagination(newPagination)}
            scroll={{
              x: 400,
            }}
            rowKey="id"
          />
        ) : ( emptyData ? <p>ไม่พบข้อมูล</p> : (
          <p>Loading</p>
        ))}
        </Col>
        <Col span={1} />

      </Row>
    </div>

  )}

export default FetchCustomer