import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Input, Space, Button, message, Tag, Layout, Row, Col, Typography, Form, Modal, DatePicker, TimePicker, Select, Spin} from 'antd'
import { SearchOutlined,ExclamationCircleOutlined, TagsOutlined, DownloadOutlined, ToTopOutlined } from '@ant-design/icons';
import moment from "moment";
import { AppContext } from '../App';
import dayjs from 'dayjs'

const { Header, Content } = Layout;
const { Title } = Typography;
const { RangePicker } = DatePicker;


const ListCoupon = () => {
  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])
  const [customerData, setCustomerData] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: Math.floor(window.screen.height/125),
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [onCheckTransaction, setOnCheckTransaction] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModalQrcode, setOpenModalQrcode] = useState(false)

  const [modalContent, setModalContent] = useState('')
  const [promotionSelected, setPromotionSelected] = useState('');
  const [messageConfirmDelete, setMessageConfirmDelete] = useState('ยืนยันการลบโปรโมชั่น');

  const [allData,setAllData] = useState([])
  const [activeData,setActiveData] = useState([])
  const [deactiveData,setDeactiveData] = useState([])

  const [allTotal,setallTotal] = useState(0)
  const [activeTotal,setActiveTotal] = useState(0)
  const [deactiveTotal,setDeactiveTotal] = useState(0)

  const [typeAll, setTypeAll] = useState('primary')
  const [typeActive, setTypeActive] = useState('')
  const [typeDeactive, setTypeDeactive] = useState('')

  const [paymentCheckout,setPaymentCheckout] = useState([])
  const [branch,setBranch] =useState([])


  const [requirePaymentCode, setRequirePaymentCode] = useState(false)
  const [requirePaymentType, setRequirePaymentType] = useState(true)
  const [promotionStartDate, setPromotionStartDate] = useState('')
  const [promotionEndDate, setPromotionEndDate] = useState('')
  const [promotionStartTime, setPromotionStartTime] = useState('')
  const [promotionEndTime, setPromotionEndTime] = useState('')

  const [optionDiscountType, setOptionDiscountType] = useState([])
  const [optionFixed, setOptionFixed] = useState([{value:'fixed',name:'คงที่'}])
  const [optionAll, setOptionAll] = useState([{value:'fixed',name:'คงที่'},{value:'percent',name:'เปอร์เซ็นต์'}])

  const [labelDiscountType, setLabelDiscountType] = useState('ประเภทส่วนลด');
  const [labelDiscountAmount, setLabelDiscountAmount] = useState('จำนวนส่วนลด (บาท / เท่า / %)');
  const [requireBranchSelect, setRequireBranchSelect] = useState(true)
  const [requireTopupAmount, setRequireTopupAmount] = useState(false)

  const [requireMachineType, setRequireMachineType] = useState(true)
  const [requireMachineSize, setRequireMachineSize] = useState(true)

  const [requiredFormTopupAmount, setRequiredFormTopupAmount] = useState(false)
  const [requiredFormMachineType, setRequiredFormMachineType] = useState(true)
  const [requiredFormMachineSize, setRequiredFormMachineSize] = useState(true)
  const [requiredFormPaymentType, setRequiredFormPaymentType] = useState(true)

  const [form] = Form.useForm();

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
          style={{
          padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
      >
          <Input
          ref={searchInput}
          placeholder={`ค้นหาโปรโมชั่น`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
              marginBottom: 8,
              display: 'block',
          }}
          />
          <Space>
          <Button
              type="primary"
              icon={<SearchOutlined />}
              size="small"
              style={{
              width: 120,
              }}
              onClick={() => {
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
          >
              Search
          </Button>
          <Button
              onClick={() => {
                  clearFilters
                  handleReset(clearFilters)
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
              size="small"
              style={{
              width: 50,
              }}
          >
              Reset
          </Button>
          </Space>
      </div>
      ),
      filterIcon: (filtered) => (
      <SearchOutlined
          style={{
          color: filtered ? '#000000' : undefined,
          }}
      />
      ),
      onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
      if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
      }
      },
  });
  
  const columns = [
    {
      title: 'ชื่อโปรโมชั่น',
      width: 200,
      dataIndex: 'name',
      key: 'name',
      // fixed: 'left',
      ...getColumnSearchProps('name'),
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }} onClick={()=>showQrcode(record)}>
          {text}
        </div>
      )    
    },
    {
        title: 'สาขาที่เข้าร่วม',
        width: 80,
        dataIndex: 'listBranchName',
        key: 'listBranchName',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text ? text.toString() : ''}
          </div>
        ),
    },
    {
        title: 'ช่วงวันที่โปรโมชั่น',
        width: 100,
        dataIndex: 'promotionStart',
        key: 'promotionStart',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text ?  moment(record.promotionStart).format('DD/MM/YYYY') + " - " +  moment(record.promotionEnd).format('DD/MM/YYYY') : ''}
          </div>
        ),
    },
    {
        title: 'ช่วงเวลาโปรโมชั่น',
        width: 80,
        dataIndex: 'promotionStart',
        key: 'promotionStart',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text ?  record.promotionTimeStart + " - " +  record.promotionTimeEnd : ''}
          </div>
        ),
    },
    {
        title: 'สถานะ',
        width: 90,
        dataIndex: 'status',
        key: 'status',
        render: (status, record) => (
          <div>
             {status === 'active' ? <Tag color='green'>เปิดใช้งาน</Tag> : (status === 'deactive' ? <Tag color='orange'>ปิดใช้งาน</Tag> : '')}
          </div>
        ),
    },

    {
      title: 'การจัดการ',
      key: 'action',
      // fixed: 'right',
      width: 120,
      render: (text, record) => (
        <div>
             { <div><Button type='primary' onClick={()=>showQrcode(record)}>ดู</Button> <Button type='primary' onClick={()=>showEditModal(record)}>แก้ไข</Button> <Button onClick={() => showDeleteModal(record)} danger>ลบ</Button> </div> }
        </div>
      ),
    },
  ]

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}listPromotion`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: pagination.current,
          pageSize: pagination.pageSize,
        },
      })

      const adjustedData = response.data.map((promotion) => ({
        ...promotion,
        key: promotion.id,
      }));

      setData(adjustedData)

      const activeData = adjustedData.filter((item) => item.status === 'active')
      const deactiveData = adjustedData.filter((item) => item.status === 'deactive')

      setAllData(adjustedData)
      setActiveData(activeData)
      setDeactiveData(deactiveData)


      setallTotal(adjustedData.length)
      setActiveTotal(activeData.length)
      setDeactiveTotal(deactiveData.length)


      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          total: response.total,
          pageSize: Math.floor(window.screen.height / 90),
        };
      });

    } catch (error) {
      console.log('error', error)
    }
  }

    const getBranch = async () => {
        try {
            const response = await axios.get(`${API_URL}branchListSelect`,
                {
                    headers: { 'Authorization': `bearer ${token}` }
                })
            setBranch(response.data);

        } catch (error) {
            console.log(error);
        }
    }

    const getListPayment = async () => {
        try {
            const response = await axios.get(`${API_URL}paymentActiveCheckout`,
                {
                    headers: { 'Authorization': `bearer ${token}` }
                })
            setPaymentCheckout(response.data);

        } catch (error) {
            console.log(error);
        }
    }
  
    const checkPaymentTypeSelected = async function (value) {
        if(value == 'payment'){
            setRequirePaymentCode(true)
        }else{
            setRequirePaymentCode(false)
        }
    }

    const changeDiscountType = async function (value) {

        if(value == 'discount'){
            setOptionDiscountType(optionAll)
            form.setFieldsValue({paymentCode: '', paymentType: ''});
            setLabelDiscountType('ประเภทส่วนลด')
            setLabelDiscountAmount('จำนวนส่วนลด (บาท / %)')
            setRequireBranchSelect(true);
            setRequireTopupAmount(false);
            setRequirePaymentType(true);
            setRequirePaymentCode(false);

            setRequireMachineType(true);
            setRequireMachineSize(true);

            setRequiredFormTopupAmount(false)
            setRequiredFormMachineType(true)
            setRequiredFormMachineSize(true)
            setRequiredFormPaymentType(true)

        }else if(value == 'cashback'){

            setOptionDiscountType(optionFixed)
            form.setFieldsValue({ discountType: 'fixed',  paymentType: '', paymentCode: '' });
            setLabelDiscountType('วิธีการคำนวณเงินคืน')
            setLabelDiscountAmount('จำนวนเงินคืน (บาท)')
            setRequireBranchSelect(true);
            setRequireTopupAmount(false);
            setRequirePaymentType(true);
            setRequirePaymentCode(false);

            setRequireMachineType(true);
            setRequireMachineSize(true);

            setRequiredFormTopupAmount(false)
            setRequiredFormMachineType(true)
            setRequiredFormMachineSize(true)
            setRequiredFormPaymentType(true)

        }else if(value =='earnpoint'){
            
            setOptionDiscountType(optionFixed)
            form.setFieldsValue({ discountType: 'fixed', paymentType: '', paymentCode: '' });
            setLabelDiscountType('วิธีการคำนวณคะแนน')
            setLabelDiscountAmount('จำนวนเท่าของคะแนน (เท่า)')
            setRequireBranchSelect(true);
            setRequireTopupAmount(false);
            setRequirePaymentType(true);
            setRequirePaymentCode(false);

            setRequireMachineType(true);
            setRequireMachineSize(true);

            setRequiredFormTopupAmount(false)
            setRequiredFormMachineType(true)
            setRequiredFormMachineSize(true)
            setRequiredFormPaymentType(true)

        }else if(value == 'topup'){

            setOptionDiscountType(optionAll)
            form.setFieldsValue({ paymentCode: '', paymentType: '', paymentCode: 'all' });
            setLabelDiscountType('วิธีการคำนวณคะแนน')
            setLabelDiscountAmount('จำนวนคะแนนที่ได้รับ (คะแนน / %)')
            setRequireBranchSelect(false);
            setRequireTopupAmount(true);
            setRequirePaymentType(false);
            setRequirePaymentCode(true);

            setRequireMachineType(false);
            setRequireMachineSize(false);

            setRequiredFormTopupAmount(true)
            setRequiredFormMachineType(false)
            setRequiredFormMachineSize(false)
            setRequiredFormPaymentType(false)
        }
    }

    const addPromotion = async (values) => {

        var paymentType,paymentCode,topupAmount,machineType,machineSize,branchList
        if(values.paymentType){paymentType = values.paymentType;}
        if(values.paymentCode){paymentCode = values.paymentCode;}
        if(values.topupAmount){topupAmount = values.topupAmount;}
        if(values.machineType){machineType = values.machineType;}
        if(values.machineSize){machineSize = values.machineSize;}
        if(values.branchList){branchList = values.branchList;}


        try {
            const response = await axios.post(`${API_URL}createPromotion`, {
                name: values.promotionName,
                status: values.promotionStatus,
                promotionType: values.promotionType,
                discountType: values.discountType,
                discountAmount: values.discountAmount,
                branchList: branchList,
                promotionStart: promotionStartDate,
                promotionEnd: promotionEndDate,
                promotionTimeStart: promotionStartTime,
                promotionTimeEnd: promotionEndTime,
                promotionDays: values.promotionDays,
                machineType: machineType,
                machineSize: machineSize,
                paymentType: paymentType,
                paymentCode: paymentCode,
                topupAmount: topupAmount,
            }, {
                headers: {
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
    
            if (response.status === 200) {
                messageApi.open({
                    type: 'success',
                    content: 'เพิ่มโปรโมชั่นเรียบร้อย',
                });
                fetchData()
            }
        } catch (error) {
            console.log(error);

        }

    }
    
    const editPromotion = async (values) => {

        var paymentType,paymentCode,topupAmount,machineType,machineSize,branchList
        if(values.branchList){branchList = values.branchList;}
        if(values.paymentType){paymentType = values.paymentType;}
        if(values.paymentCode){paymentCode = values.paymentCode;}
        if(values.topupAmount){topupAmount = values.topupAmount;}
        if(values.machineType){machineType = values.machineType;}
        if(values.machineSize){machineSize = values.machineSize;}

        try {
            const response = await axios.post(`${API_URL}editPromotion`, {
                promotionId: values.promotionId,
                name: values.promotionName,
                status: values.promotionStatus,
                promotionType: values.promotionType,
                discountType: values.discountType,
                discountAmount: values.discountAmount,
                branchList: branchList,
                promotionStart: promotionStartDate,
                promotionEnd: promotionEndDate,
                promotionTimeStart: promotionStartTime,
                promotionTimeEnd: promotionEndTime,
                promotionDays: values.promotionDays,
                machineType: machineType,
                machineSize: machineSize,
                paymentType: paymentType,
                paymentCode: paymentCode,
                topupAmount: topupAmount,
            }, {
                headers: {
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
    
            if (response.status === 200) {
                messageApi.open({
                    type: 'success',
                    content: 'แก้ไขโปรโมชั่นเรียบร้อย',
                });
                fetchData()
            }
        } catch (error) {
            console.log(error);

        }

    }

    const deletePromotion = async () => {
        try {
            const response = await axios.post(`${API_URL}deletePromotion`, {
                promotionId: promotionSelected,
            },{
              headers: {
                'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })
      
            if(response.status === 200){
                messageApi.open({
                    type: 'success',
                    content: 'ลบโปรโมชั่นเรียบร้อย',
                });
                fetchData()
            }

          } catch (error) {
            console.log('error', error)
          }
    }

  const showCreateModal = async () => {
    setOptionDiscountType(optionAll)
    setRequirePaymentCode(false)
    setOpenAddModal(true)
  }

  const showEditModal = async (record) => {

    var listPromotionDate = []
    if(record.promotionDays.includes('all')){
        listPromotionDate.push('all') 
    }else{
        listPromotionDate = record.promotionDays.split(",");
    }

    if(record.paymentType == "payment"){ setRequirePaymentCode(true) }
    else{ setRequirePaymentCode(false) }

    if(record.promotionType == 'discount'){

        setOptionDiscountType(optionAll)
        setLabelDiscountType('ประเภทส่วนลด')
        setLabelDiscountAmount('จำนวนส่วนลด (บาท / %)')

    }else if(record.promotionType  == 'cashback'){

        setOptionDiscountType(optionFixed)
        setLabelDiscountType('วิธีการคำนวณเงินคืน')
        setLabelDiscountAmount('จำนวนเงินคืน (บาท)')

    }else if(record.promotionType  == 'earnpoint'){

        setOptionDiscountType(optionFixed)
        setLabelDiscountType('วิธีการคำนวณคะแนน')
        setLabelDiscountAmount('จำนวนเท่าของคะแนน (เท่า)')

    }else if(record.promotionType == 'topup'){

        setOptionDiscountType(optionAll)
        setLabelDiscountType('วิธีการคำนวณคะแนน')
        setLabelDiscountAmount('จำนวนคะแนนที่ได้รับ (คะแนน / %)')
    }

    switch (record.promotionType) {
        case 'discount':
        case 'cashback':
        case 'earnpoint':
            setRequireMachineType(true);
            setRequireMachineSize(true);
            setRequirePaymentType(true);
            setRequireBranchSelect(true);
            setRequireTopupAmount(false);

            setRequiredFormMachineType(true);
            setRequiredFormMachineSize(true);
            setRequiredFormPaymentType(true);
            setRequiredFormTopupAmount(false);
            break;

        case 'topup':
            setRequireMachineType(false);
            setRequireMachineSize(false);
            setRequirePaymentType(false);
            setRequireBranchSelect(false);
            setRequireTopupAmount(true);
            setRequirePaymentCode(true);

            setRequiredFormMachineType(false);
            setRequiredFormMachineSize(false);
            setRequiredFormPaymentType(false);
            setRequiredFormTopupAmount(true);
            break;

        default:
            setRequireMachineType(true);
            setRequireMachineSize(true);
            setRequirePaymentType(true);
            setRequireBranchSelect(true);
            setRequireTopupAmount(false);

            setRequiredFormMachineType(true);
            setRequiredFormMachineSize(true);
            setRequiredFormPaymentType(true);
            setRequiredFormTopupAmount(false);
    }

    setPromotionStartDate(record.promotionStart)
    setPromotionEndDate(record.promotionEnd)
  
    setPromotionStartTime(record.promotionTimeStart);
    setPromotionEndTime(record.promotionTimeEnd);  

    form.setFieldsValue({
        promotionId: record.id,
        promotionName: record.name,
        promotionStatus: record.status,
        promotionType: record.promotionType,
        discountType: record.discountType,
        discountAmount: record.discountAmount,
        branchList: record.listBranchId,
        promotionDate: [dayjs(record.promotionStart),dayjs(record.promotionEnd)],
        promotionTime: [dayjs(record.promotionTimeStart, 'HH:mm'),dayjs(record.promotionTimeEnd, 'HH:mm')],
        promotionDays: listPromotionDate,
        machineType: record.machineType,
        machineSize: record.machineSize,
        paymentType: record.paymentType,
        paymentCode: record.paymentCode,
        topupAmount: record.topupAmount,
    });

    setOpenEditModal(true)
  }

  const showDeleteModal = async (record) => {
    setPromotionSelected(record.id)
    setOpenDeleteModal(true)
  }

  const showQrcode = async (record) => {

        setOpenModalQrcode(true)
        
        const machineType = record.machineType
        const machineSize = record.machineSize
        const paymentType = record.paymentType
        const promotionType = record.promotionType
        const discountType = record.discountType
        const discountAmount = record.discountAmount
        const listBranchName = record.listBranchName

        var stringPromotionDays = (record.promotionDays).toString();
        if(stringPromotionDays.includes('all')){ stringPromotionDays = 'ทุกวัน'}
        else{
            stringPromotionDays = stringPromotionDays.replace("Mon", "จันทร์");
            stringPromotionDays = stringPromotionDays.replace("Tue", "อังคาร");
            stringPromotionDays = stringPromotionDays.replace("Wed", "พุธ");
            stringPromotionDays = stringPromotionDays.replace("Thu", "พฤหัสบดี");
            stringPromotionDays = stringPromotionDays.replace("Fri", "ศุกร์");
            stringPromotionDays = stringPromotionDays.replace("Sat", "เสาร์");
            stringPromotionDays = stringPromotionDays.replace("Sun", "อาทิตย์");
         }

        setModalContent(
            <div className='ModalContentLeft'>
                <br/>
                <p><b>ชื่อโปรโมชั่น: </b> {record.name}</p>
                <p><b>ประเภทโปรโมชั่น: </b> {promotionType ? (promotionType == 'discount' ? 'ส่วนลด' : (promotionType == 'earnpoint' ? 'ได้รับคะแนน' : (promotionType == 'cashback' ? 'เงินคืน' : ( promotionType == 'topup' ? 'เติมเงินได้รับคะแนน' : promotionType))) ) : ''}</p>
                <p><b>{promotionType ? (promotionType == 'discount' ? 'ประเภทส่วนลด' : (promotionType == 'earnpoint' ? 'ประเภทการได้รับคะแนน' : (promotionType == 'cashback' ? 'ประเภทการได้รับเงินคืน' : ( promotionType == 'topup' ? 'ประเภทการได้รับคะแนน' : promotionType))) ) : 'ประเภทส่วนลด'}: </b> {discountType ? (discountType == 'fixed' ? 'คงที่' : (discountType == 'percent' ? 'เปอร์เซ็นต์' : (discountType == 'free' ? 'ฟรี' : discountType)) ) : ''}</p>
                <p><b>จำนวน: </b> {discountAmount ? discountAmount : ''} { discountType == 'percent' ? " %" : (promotionType == 'earnpoint' ? 'เท่า' : ( promotionType == 'discount' ? 'บาท' : ( promotionType == 'topup' ? 'คะแนน' : ''))) }</p>
                <p><b>{promotionType ? ( promotionType == 'topup' ? 'ยอดการเติมเงินขั้นต่ำ : ' : '' ) : ""}</b> {promotionType ? ( promotionType == 'topup' ? record.topupAmount + " บาท" : '' ) : ""}</p>
                <br/>
                <p><b>ช่วงวันที่โปรโมชั่น: </b> {record.promotionStart ?  moment(record.promotionStart).format('DD/MM/YYYY') + " - " +  moment(record.promotionEnd).format('DD/MM/YYYY') : ''}</p>
                <p><b>ช่วงเวลาโปรโมชั่น: </b> {record.promotionTimeStart ?  record.promotionTimeStart + " - " +  record.promotionTimeEnd : ''}</p>
                <p><b>วันที่เข้าร่วม (รายสัปดาห์): </b> {stringPromotionDays ?  stringPromotionDays : ''}</p>
                <br/>
                <p><b>สาขาที่เข้าร่วม: </b> {listBranchName ? listBranchName.toString() : ''}</p>
                <br/>
                <p><b>ประเภทเครื่อง: </b> { promotionType == 'topup' ? '' : (machineType ? (machineType == 'all' ? "ทุกประเภท" : (machineType == 'washer' ? 'เครื่องซัก' : (machineType == 'dryer' ? 'เครื่องอบ' : (machineType == 'washAndDry' ? 'เครื่องซักอบ' : machineType ) ) ) ) : '') }</p>
                <p><b>ขนาดเครื่อง(กก.): </b> { promotionType == 'topup' ? '' : ( machineSize == 'all' ? 'ทุกขนาด' : machineSize )}</p>
                <br/>
                <p><b>ประเภทการชำระเงิน: </b> { paymentType ? (paymentType == 'all' ? 'ทุกประเภท' : (paymentType == 'wallet' ? 'กระเป๋าเงิน' : (paymentType == 'payment' ? ( record.paymentCode == 'all' ? 'ทุกช่องทางการชำระออนไลน์': record.paymentCode) : paymentType))) : ''} {promotionType ? ( promotionType == 'topup' ? (record.paymentCode == 'all' ? 'ทุกช่องทาง' : record.paymentCode) : '' ) : ""}</p>
            </div>
        )
    }
        

  const handleCancelQrcode = () => {
    setOpenModalQrcode(false)
  }

  const setPromotionDate = (value,dateString) =>{
    setPromotionStartDate(dateString[0])
    setPromotionEndDate(dateString[1])
  }

  const setPromotionTime = async (time) => {
    if(time){
        setPromotionStartTime(dayjs(time[0]).format('HH:mm'));
        setPromotionEndTime(dayjs(time[1]).format('HH:mm'));    
    }
  }

  const setDisplayData = function (status){
    if(status === 'all'){
      setData(allData)

      setTypeAll('primary')
      setTypeActive('')
      setTypeDeactive('')
    }else if(status === 'active'){
        setData(activeData)

        setTypeAll('')
        setTypeActive('primary')
        setTypeDeactive('')
    }else if(status === 'deactive'){
        setData(deactiveData)

        setTypeAll('')
        setTypeActive('')
        setTypeDeactive('primary')
    }
  }


  useEffect(() => {
    fetchData()
    getBranch()
    getListPayment()
    setOptionDiscountType(optionAll)
  }, [])

  return (
    <div className="fetch-event-table">
      {contextHolder}
      {
        <Modal
        title='รายละเอียดโปรโมชั่น'
        centered
        open={openModalQrcode}
        footer={null}
        width={700}
        onCancel={handleCancelQrcode}
        >
        {modalContent}
        </Modal>
      }
      {
        <Modal
        title="เพิ่มโปรโมชั่น"
        centered
        open={openAddModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={() => {
            form
                .validateFields()
                .then((values) => {
                    addPromotion(values)
                    form.resetFields();
                    setOpenAddModal(false);
                })
                .catch((errorInfo) => {
                    console.log('Failed:', errorInfo);
                });
        }}
        onCancel={() => {
            window.location.reload();
            form.resetFields();
            setOpenAddModal(false);
        }}
        width={400}
    >
        <Form
            form={form}
            layout="vertical"
        >
            <Form.Item name="promotionName" label="ชื่อโปรโมชั่น"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกชื่อโปรโมชั่น',
                    },
                ]}>
                <Input type='text'/>
            </Form.Item>
            <Form.Item name="promotionStatus" label="สถานะ"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุสถานะ',
                            },
                        ]}>
               <Select>
                    <Select.Option value="active">เปิดใช้งาน</Select.Option>
                    <Select.Option value="deactive">ปิดใช้งาน</Select.Option>
                </Select>
              </Form.Item>

            <br/>

            <Form.Item name="promotionType" label="ประเภทโปรโมชั่น"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุประเภทโปรโมชั่น',
                            },
                        ]}>
               <Select onChange={changeDiscountType}>
                    <Select.Option value="discount">ส่วนลดค่าเครื่อง</Select.Option>
                    <Select.Option value="earnpoint">ได้รับคะแนนเพิ่ม(เท่า)</Select.Option>
                    <Select.Option value="cashback">เครดิตเงินคืน</Select.Option>
                    <Select.Option value="topup">เติมเงินได้รับคะแนน</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="discountType" label={labelDiscountType}
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุประเภทส่วนลด',
                            },
                        ]}>
               <Select>
                    {
                        optionDiscountType.map(optionOne => (
                            <Select.Option key={optionOne.value} value={optionOne.value} label={optionOne.name}>{optionOne.name}</Select.Option>
                        ))
                    }
                </Select>
              </Form.Item>

              <Form.Item name="discountAmount" label={labelDiscountAmount}
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุจำนวนส่วนลดให้ถูกต้อง',
                                pattern: new RegExp("(^[0-9]*$)"),

                            },
                        ]}>
                        <Input type='text' />
              </Form.Item>

              <Form.Item name="topupAmount" label="ยอดเติมเงินขั้นต่ำ (บาท)" hidden={!requireTopupAmount}
                        rules={[
                            {
                                required: requiredFormTopupAmount,
                                message: 'กรุณาระบุจำนวนยอดเติมเงินให้ถูกต้อง',
                                pattern: new RegExp("(^[0-9]*$)"),

                            },
                        ]}>
                        <Input type='text' />
              </Form.Item>

              <br/>

              <Form.Item name="branchList" label="สาขาที่เข้าร่วม" hidden={!requireBranchSelect}>
                <Select     
                    allowClear  
                    mode="multiple"
                    showSearch
                    optionFilterProp="label"
                >
                    {branch.map(branchOne => (
                        <Select.Option key={branchOne.id} value={branchOne.id} label={branchOne.name}>{branchOne.name}</Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <br/>

              <Form.Item name="promotionDate" label="ช่วงวันที่โปรโมชั่น"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุช่วงวันที่โปรโมชั่น',

                            },
                        ]}>
                <RangePicker onChange={setPromotionDate}/>
              </Form.Item>

              <Form.Item name="promotionTime" label="ช่วงเวลาโปรโมชั่น" 
                rules={[
                    {
                        required: true,
                        message: 'กรุณาระบุช่วงเวลาโปรโมชั่น',

                    },
                ]}>
                        <TimePicker.RangePicker format={'HH:mm'} onChange={setPromotionTime}/>
                </Form.Item>

                <Form.Item name="promotionDays" label="วันที่เข้าร่วมโปรโมชั่น (ใน 1 สัปดาห์)"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุวันที่เข้าร่วมโปรโมชั่น',

                            },
                        ]}>
                <Select
                    allowClear  
                    mode="multiple"
                    optionFilterProp="label"
                >
                    <Select.Option value="all">ทุกวัน</Select.Option>
                    <Select.Option value="Mon">จันทร์</Select.Option>
                    <Select.Option value="Tue">อังคาร</Select.Option>
                    <Select.Option value="Wed">พุธ</Select.Option>
                    <Select.Option value="Thu">พฤหัสบดี</Select.Option>
                    <Select.Option value="Fri">ศุกร์</Select.Option>
                    <Select.Option value="Sat">เสาร์</Select.Option>
                    <Select.Option value="Sun">อาทิตย์</Select.Option>
                </Select>            
              </Form.Item>

                <br/>

              <Form.Item name="machineType" label="ประเภทเครื่อง" hidden={!requireMachineType}
                rules={[
                    {
                        required: requiredFormMachineType,
                        message: 'กรุณากรอกประเภทเครื่อง',
                    },
                ]}>
                <Select>
                    <Select.Option value="washer">เครื่องซัก</Select.Option>
                    <Select.Option value="dryer">เครื่องอบ</Select.Option>
                    <Select.Option value="all">ทุกประเภท</Select.Option>
                </Select>            
            </Form.Item>
            <Form.Item name="machineSize" label="ขนาดเครื่อง(กก.)" hidden={!requireMachineSize}
                rules={[
                    {
                        required: requiredFormMachineSize,
                        message: 'กรุณาระบุขนาดเครื่อง',
                    },
                ]}>
                <Select>
                    <Select.Option value="9">9 กิโลกรัม</Select.Option>
                    <Select.Option value="10">10 กิโลกรัม</Select.Option>
                    <Select.Option value="13">13 กิโลกรัม</Select.Option>
                    <Select.Option value="14">14 กิโลกรัม</Select.Option>
                    <Select.Option value="15">15 กิโลกรัม</Select.Option>
                    <Select.Option value="23">23 กิโลกรัม</Select.Option>
                    <Select.Option value="27">27 กิโลกรัม</Select.Option>
                    <Select.Option value="all">ทุกขนาด</Select.Option>
                </Select>  
            </Form.Item>
            <Form.Item name="paymentType" label="ประเภทการชำระเงิน" hidden={!requirePaymentType}
                rules={[
                    {
                        required: requiredFormPaymentType,
                        message: 'กรุณาระบุประเภทการชำระเงิน',
                    },
                ]}>
                <Select onChange={checkPaymentTypeSelected}  >
                    <Select.Option value="wallet">กระเป๋าเงิน</Select.Option>
                    <Select.Option value="payment">ชำระเงิน</Select.Option>
                    <Select.Option value="all">ทุกประเภท</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="paymentCode" label="ช่องทางการชำระ" hidden={!requirePaymentCode}>
                <Select     
                    allowClear                   
                    showSearch
                    optionFilterProp="label"
                >
                    <Select.Option key='all' value='all' label='ทุกช่องทาง'>ทุกช่องทาง</Select.Option>
                    {paymentCheckout.map(payment => (
                        <Select.Option key={payment.code} value={payment.code} label={payment.description}>{payment.description}</Select.Option>
                    ))}
                </Select>
              </Form.Item>  
        </Form>
        </Modal>
      }
      {
        <Modal
        title="แก้ไขโปรโมชั่น"
        centered
        open={openEditModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={() => {
            form
                .validateFields()
                .then((values) => {
                    editPromotion(values)
                    form.resetFields();
                    setOpenEditModal(false);
                })
                .catch((errorInfo) => {
                    console.log('Failed:', errorInfo);
                });
        }}
        onCancel={() => {
            form.resetFields();
            setOpenEditModal(false);
        }}
        width={400}
    >
        <Form
            form={form}
            layout="vertical"
        >
            <Form.Item name="promotionId" hidden={true}> <Input type='hidden'/> </Form.Item>           
            <Form.Item name="promotionName" label="ชื่อโปรโมชั่น"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกชื่อโปรโมชั่น',
                    },
                ]}>
                <Input type='text'/>
            </Form.Item>
            <Form.Item name="promotionStatus" label="สถานะ"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุสถานะ',
                            },
                        ]}>
               <Select>
                    <Select.Option value="active">เปิดใช้งาน</Select.Option>
                    <Select.Option value="deactive">ปิดใช้งาน</Select.Option>
                </Select>
              </Form.Item>

            <br/>

            <Form.Item name="promotionType" label="ประเภทโปรโมชั่น"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุประเภทโปรโมชั่น',
                            },
                        ]}>
               <Select onChange={changeDiscountType}>
                    <Select.Option value="discount">ส่วนลดค่าเครื่อง</Select.Option>
                    <Select.Option value="earnpoint">ได้รับคะแนนเพิ่ม(เท่า)</Select.Option>
                    <Select.Option value="cashback">เครดิตเงินคืน</Select.Option>
                    <Select.Option value="topup">เติมเงินได้รับคะแนน</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="discountType" label={labelDiscountType}
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุประเภทส่วนลด',
                            },
                        ]}>
               <Select>
                    {
                        optionDiscountType.map(optionOne => (
                            <Select.Option key={optionOne.value} value={optionOne.value} label={optionOne.name}>{optionOne.name}</Select.Option>
                        ))
                    }
                </Select>
              </Form.Item>

              <Form.Item name="discountAmount" label={labelDiscountAmount}
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุจำนวนส่วนลดให้ถูกต้อง',
                                pattern: new RegExp("(^[0-9]*$)"),

                            },
                        ]}>
                        <Input type='text' />
              </Form.Item>

              <Form.Item name="topupAmount" label="ยอดเติมเงินขั้นต่ำ (บาท)" hidden={!requireTopupAmount}
                        rules={[
                            {
                                required: requiredFormTopupAmount,
                                message: 'กรุณาระบุจำนวนยอดเติมเงินให้ถูกต้อง',
                                pattern: new RegExp("(^[0-9]*$)"),

                            },
                        ]}>
                        <Input type='text' />
              </Form.Item>

              <br/>

              <Form.Item name="branchList" label="สาขาที่เข้าร่วม" hidden={!requireBranchSelect}>
                <Select     
                    allowClear  
                    mode="multiple"
                    showSearch
                    optionFilterProp="label"
                >
                    {branch.map(branchOne => (
                        <Select.Option key={branchOne.id} value={branchOne.id} label={branchOne.name}>{branchOne.name}</Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <br/>

              <Form.Item name="promotionDate" label="ช่วงวันที่โปรโมชั่น"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุช่วงวันที่โปรโมชั่น',

                            },
                        ]}>
                <RangePicker onChange={setPromotionDate}/>
              </Form.Item>

              <Form.Item name="promotionTime" label="ช่วงเวลาโปรโมชั่น" 
                rules={[
                    {
                        required: true,
                        message: 'กรุณาระบุช่วงเวลาโปรโมชั่น',

                    },
                ]}>
                        <TimePicker.RangePicker format={'HH:mm'} onChange={setPromotionTime}/>
                </Form.Item>

                <Form.Item name="promotionDays" label="วันที่เข้าร่วมโปรโมชั่น (ใน 1 สัปดาห์)"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุวันที่เข้าร่วมโปรโมชั่น',

                            },
                        ]}>
                <Select
                    allowClear  
                    mode="multiple"
                    optionFilterProp="label"
                >
                    <Select.Option value="all">ทุกวัน</Select.Option>
                    <Select.Option value="Mon">จันทร์</Select.Option>
                    <Select.Option value="Tue">อังคาร</Select.Option>
                    <Select.Option value="Wed">พุธ</Select.Option>
                    <Select.Option value="Thu">พฤหัสบดี</Select.Option>
                    <Select.Option value="Fri">ศุกร์</Select.Option>
                    <Select.Option value="Sat">เสาร์</Select.Option>
                    <Select.Option value="Sun">อาทิตย์</Select.Option>
                </Select>            
              </Form.Item>

                <br/>

              <Form.Item name="machineType" label="ประเภทเครื่อง" hidden={!requireMachineType}
                rules={[
                    {
                        required: requiredFormMachineType,
                        message: 'กรุณากรอกประเภทเครื่อง',
                    },
                ]}>
                <Select>
                    <Select.Option value="washer">เครื่องซัก</Select.Option>
                    <Select.Option value="dryer">เครื่องอบ</Select.Option>
                    <Select.Option value="all">ทุกประเภท</Select.Option>
                </Select>            
            </Form.Item>
            <Form.Item name="machineSize" label="ขนาดเครื่อง(กก.)" hidden={!requireMachineSize}
                rules={[
                    {
                        required: requiredFormMachineSize,
                        message: 'กรุณาระบุขนาดเครื่อง',
                    },
                ]}>
                <Select>
                    <Select.Option value="9">9 กิโลกรัม</Select.Option>
                    <Select.Option value="10">10 กิโลกรัม</Select.Option>
                    <Select.Option value="13">13 กิโลกรัม</Select.Option>
                    <Select.Option value="14">14 กิโลกรัม</Select.Option>
                    <Select.Option value="15">15 กิโลกรัม</Select.Option>
                    <Select.Option value="23">23 กิโลกรัม</Select.Option>
                    <Select.Option value="27">27 กิโลกรัม</Select.Option>
                    <Select.Option value="all">ทุกขนาด</Select.Option>
                </Select>  
            </Form.Item>
            <Form.Item name="paymentType" label="ประเภทการชำระเงิน" hidden={!requirePaymentType}
                rules={[
                    {
                        required: requiredFormPaymentType,
                        message: 'กรุณาระบุประเภทการชำระเงิน',
                    },
                ]}>
                <Select onChange={checkPaymentTypeSelected}  >
                    <Select.Option value="wallet">กระเป๋าเงิน</Select.Option>
                    <Select.Option value="payment">ชำระเงิน</Select.Option>
                    <Select.Option value='all'>ทุกประเภท</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="paymentCode" label="ช่องทางการชำระ" hidden={!requirePaymentCode}>
                <Select     
                    allowClear                   
                    showSearch
                    optionFilterProp="label"
                >
                    <Select.Option key='all' value='all' label='ทุกช่องทาง'>ทุกช่องทาง</Select.Option>
                    {paymentCheckout.map(payment => (
                        <Select.Option key={payment.code} value={payment.code} label={payment.description}>{payment.description}</Select.Option>
                    ))}
                </Select>
              </Form.Item>  
        </Form>
        </Modal>
      }
       <Modal
            title={
                <>
                    <ExclamationCircleOutlined style={{ color: 'red', marginRight: '8px' }} />
                    ลบโปรโมชั่น
                </>
            }
            centered
            open={openDeleteModal}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            onOk={() => {
                deletePromotion()
                setOpenDeleteModal(false)
            }}
            onCancel={() => {
                setOpenDeleteModal(false);
            }}
            width={300}
        >
            {messageConfirmDelete}
        </Modal>
      <Row>

            <Col span={21} >
                <Title level={3} ><TagsOutlined /> โปรโมชั่น</Title>
            </Col>
                <Title level={3} ><Col span={2}><Button type='primary' onClick={()=>showCreateModal()}>เพิ่มโปรโมชั่น</Button></Col></Title>
        </Row>
        <Row>
            <Col span={24} >
            สถานะโปรโมชั่น : <Button type={typeAll} onClick={()=> setDisplayData('all')}>ทั้งหมด ({allTotal})</Button>  &nbsp;
                        <Button type={typeActive} onClick={()=> setDisplayData('active')}>ใช้งานอยู่ ({activeTotal})</Button> &nbsp;
                        <Button type={typeDeactive} onClick={()=> setDisplayData('deactive')}>ปิดใช้งาน ({deactiveTotal})</Button>  &nbsp;


            </Col>
        </Row>
        <Row>
            <Col span={24} >
                {data && data.length > 0 ? (
                    <Table
                    columns={columns}
                    dataSource={data}
                    pagination={pagination}
                    onChange={(newPagination) => setPagination(newPagination)}
                    scroll={{
                        x: 400,
                    }}
                    rowKey="id"
                    />
                ) : (
                    data.length == 0 ? 'ไม่พบข้อมูล' : <Spin />
                )}
            </Col>
        </Row>
    </div>
  )
}

export default ListCoupon