import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Input, Space, Button, message, Tag, Modal, Typography, Spin, Form, Select,Row, Col} from 'antd'
import { SearchOutlined, EyeOutlined, HistoryOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';


import { AppContext } from '../App';
import { elements } from 'chart.js';
const { Title } = Typography;

const ListLaundry = () => {

    const [form] = Form.useForm();

  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])
  const [branchName, setBranchName] = useState('')
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: Math.floor(window.screen.height/125),
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [onCheckTransaction, setOnCheckTransaction] = useState(false);

  const [openLaundryModal, setOpenLaundryModal] = useState(false)
  const [modalLaundryContent, setModalLaundryContent] = useState('')

  const [branch, setBranch] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(true)


  const [messageApi, contextHolder] = message.useMessage();

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
          style={{
          padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
      >
          <Input
          ref={searchInput}
          placeholder={`ค้นหาเบอร์โทรลูกค้า`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
              marginBottom: 8,
              display: 'block',
          }}
          />
          <Space>
          <Button
              type="primary"
              icon={<SearchOutlined />}
              size="small"
              style={{
              width: 120,
              }}
              onClick={() => {
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
          >
              Search
          </Button>
          <Button
              onClick={() => {
                  clearFilters
                  handleReset(clearFilters)
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
              size="small"
              style={{
              width: 50,
              }}
          >
              Reset
          </Button>
          </Space>
      </div>
      ),
      filterIcon: (filtered) => (
      <SearchOutlined
          style={{
          color: filtered ? '#000000' : undefined,
          }}
      />
      ),
      onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
      if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
      }
      },
  });
  
  const columns = [
    {
      title: 'เบอร์โทรลูกค้า',
      width: 50,
      dataIndex: 'phone',
      key: 'phone',
      // fixed: 'left',
      ...getColumnSearchProps('phone'),
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }} onClick={()=>showLaundryDetail(record)}>
          {text}
        </div>
      )    
    },
    {
        title: 'วันที่',
        width: 90,
        dataIndex: 'startMachineTime',
        key: 'startMachineTime',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {moment.tz(moment(text), 'Asia/Bangkok').format('DD/MM/YYYY H:mm')}
          </div>
        ),
    },
    {
        title: 'ประเภทการใช้งาน',
        width: 100,
        dataIndex: 'machineType',
        key: 'machineType',
        render: (text, record) => (
          <div>
             {text === 'dryerExtendTime' ? 'ต่อเวลาอบผ้า' : (text === 'washer' ? 'ซักผ้า' : (text === 'dryer' ? 'อบผ้า' : 'ซัก/อบผ้า'))}
          </div>
        ),
    },
    {
        title: 'เครื่องหมายเลข',
        width: 50,
        dataIndex: 'machine',
        key: 'machineNo',
        render: (text, record) => (
          <div>
             {text.machineNo}
          </div>
        ),
    },
    {
      title: 'ประเภทการชำระ',
      width: 90,
      dataIndex: 'checkoutType',
      key: 'checkoutType',
      // fixed: 'left',
      render: (text, record) => (
        <div>
          {text === 'wallet' ? 'กระเป๋าเงิน' : (text === 'payment' ?'ชำระออนไลน์' : (text === 'redeemPoint' ? 'แลกแต้ม' : (text === 'couponFree' ? 'คูปองฟรี' : '')))}
        </div>
      ),
    },{
      title: 'ช่องทางการชำระ',
      width: 90,
      dataIndex: 'paymentCode',
      key: 'paymentCode',
      render: (text, record) => (
        <div>
            {text}
        </div>
      ),
    },
    {
        title: 'ยอดชำระ',
        width: 90,
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        render: (amount, record) => (
          <div>
             {amount ? amount : 0}
          </div>
        ),
    },
    {
      title: 'รายละเอียด',
      key: 'action',
      align: 'center',
      width: 70,
      render: (text, record) => (
        
        <div>
          <a><EyeOutlined onClick={()=>showLaundryDetail(record)}/></a>
        </div>
      ),
    },
  ]

  const fetchData = async (branchId) => {
    if(!branchId){ branchId = ''}
    try {
      const response = await axios.get(`${API_URL}getLaundryListBranch/${branchId}`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: pagination.current,
          pageSize: pagination.pageSize,
        },
      })

      const adjustedData = response.data.map((laundry) => ({
        ...laundry,
        key: laundry.orderId,
      }));

      setData(adjustedData)
      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          total: response.total,
          pageSize: Math.floor(window.screen.height / 100),
        };
      });

      setLoadingStatus(false)

    } catch (error) {
      console.log('error', error)
    }
  }

  const showLaundryDetail = async(laundryDetail) => {
    setOpenLaundryModal(true)
    console.log(laundryDetail)
    setModalLaundryContent(
        <div className='ModalContentLeft'>
            <Title level={5}>ข้อมูลลูกค้า</Title>
            <p><b>หมายเลขออเดอร์:</b> {laundryDetail.orderId}</p>
            <p><b>เบอร์โทรลูกค้า:</b> {laundryDetail.customer.phone}</p>
            <p><b>วันที่:</b> {moment.tz(moment(laundryDetail.startMachineTime), 'Asia/Bangkok').format('DD/MM/YYYY H:mm')}</p>
            <br />
            <Title level={5}>ข้อมูลเครื่องซัก</Title>
            <p><b>หมายเลขเครื่อง:</b> {laundryDetail.machine.machineNo}</p>
            <p><b>ชื่อเครื่อง:</b> {laundryDetail.machine.name}</p>
            <p><b>สาขา:</b> {laundryDetail.machine.branch.name}</p>
            <p><b>เวลาที่ใช้งาน:</b> {laundryDetail.machineTime} นาที</p>
            { laundryDetail.programLaundry ? <p><b>โปรแกรม:</b> {laundryDetail.programLaundry.name}</p> : '' }
            <br />
            <Title level={5}>ข้อมูลการชำระ</Title>
            <p><b>วิธีการชำระ:</b> {laundryDetail.checkoutType === 'wallet' ? 'กระเป๋าเงิน' : (laundryDetail.checkoutType === 'payment' ?'ชำระออนไลน์' : (laundryDetail.checkoutType === 'redeemPoint' ? 'แลกแต้ม' : (laundryDetail.checkoutType === 'couponFree' ? 'คูปองฟรี' : '')))}</p>
            { laundryDetail.paymentCode ? <p><b>ช่องทางการชำระ:</b> { laundryDetail.paymentCode }</p> : '' }
            <p><b>ยอดชำระ:</b> { laundryDetail.totalAmount } บาท</p>
            <p><b>ส่วนลดคูปอง:</b> { laundryDetail.couponValue ?  laundryDetail.couponValue : 0 } บาท</p>
            <p><b>คะแนนที่ใช้แลก:</b> { laundryDetail.pointRedeem } คะแนน</p>


        </div>
    )
  }

  const getBranch = async () => {
    try {
        const response = await axios.get(`${API_URL}getBranchManager`,
            {
                headers: { 'Authorization': `bearer ${token}` }
            })
     
        if(response.data.length !== 0){
          setBranchName(response.data[0].name)
          form.setFieldsValue({
            branch: response.data[0]? response.data[0].id : [],
        });
          setBranch(response.data);
        }

    } catch (error) {
        console.log(error);
    }
  }

  const changeBranch = async (value) => {
    branch.forEach(element => {
      if(element.id === value){
        setBranchName(element.name)
      }
  });
    setLoadingStatus(true)
    setData('')
    fetchData(value)
}

  const handleCloseLaundryModal = () => {
    setOpenLaundryModal(false)
  }

  useEffect(() => {
    if(data.length === 0 && branch.length === 0){
        getBranch()
        fetchData()
    }
  }, [data, pagination.current, pagination.pageSize])

  return (
    <div className="fetch-event-table">
       {
        <Modal
        title='รายละเอียดการใช้งาน'
        centered
        open={openLaundryModal}
        footer={null}
        width={500}
        onCancel={handleCloseLaundryModal}
        >
        {modalLaundryContent}
        </Modal>
      }
      
      {contextHolder}
      <Row>
        <Col span={22} ><Title level={3} style={{textAlign:'center'}}>สาขา: {branchName}</Title></Col>
      </Row>
      <Row>
        <Col>
        <Title level={4} ><HistoryOutlined /> ประวัติการใช้งาน</Title>
        </Col>
      </Row>
      {
        <Form form={form} >
        <Form.Item name="branch" label='สาขา' className='formItemWithNoMargin'>
        <Select style={{width:150}} optionFilterProp="label" onChange={changeBranch}>
            {branch.map(branchItem => (
                <Select.Option key={branchItem.id} value={branchItem.id} label={branchItem.name}>{branchItem.name}</Select.Option>
            ))}
        </Select>
        </Form.Item>
        </Form>
      }
      {data && data.length > 0 ? (
        <Table
          columns={columns}
          dataSource={data}
          pagination={pagination}
          onChange={(newPagination) => setPagination(newPagination)}
          scroll={{
            x: 400,
          }}
          rowKey="orderId"
        />
      ) : (
         loadingStatus ? <center><Spin spinning={loadingStatus} /></center> : <center>ไม่มีข้อมูล</center>
      )}
    </div>
  )
}

export default ListLaundry