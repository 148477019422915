import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Input, Space, Button, message, Tag, Modal, Image, Spin, Form, Select, Radio, Row, Col, Card} from 'antd'

import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { AppContext } from '../App';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend,
);


const ListMachine = () => {
  
  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])

  const [form] = Form.useForm();
  const [formBranch] = Form.useForm();
  const [branch, setBranch] = useState([]);
  const [chartType, setChartType] = useState("week")
  const [countDate , setCountDate] = useState(7)
  const [branchSelected, setBranchSelected] = useState('')

  const [loadingStatus, setLoadingStatus] = useState(true)

  const [loadingChart, setLoadingChart] = useState(true)
  const [chartReady, setChartReady] = useState(false)
  const [maxValueOfChart, setMaxValueOfChart] = useState(100)
  const [chartData, setChartData] = useState({
      labels: [],
      datasets: [],
  });

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  const fetchData = async (branchId, chartType) => {

    try {
      var response = await axios.get(`${API_URL}getMachineUsageReport/${chartType}/${branchId}`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })


        var maxValue = response.data.maxValueOfChart
        var listMachine = response.data.listMachine
        var listMachineNo = response.data.listMachineNo

        if(maxValue > 10000){
          maxValue = maxValue + 5000
        }else if(maxValue > 1000){
          maxValue = maxValue + 1000
        }else{
          maxValue = maxValue + 100 
        } 

        setMaxValueOfChart(maxValue)
        setData(listMachine)

        var backgroundColorType = []
        for(let machineOne of listMachine){
          if(machineOne.type == 'washer'){
            backgroundColorType.push('#4bc0c0')
          }else if(machineOne.type == 'dryer'){
            backgroundColorType.push('#ee632f')
          }else{
            backgroundColorType.push('#FF1FFFAA')
          }
        }

        const data = {
          labels: listMachineNo,
          datasets: [{
            data: listMachine.map((x) => x.usageTime ? x.usageTime : 0),
            backgroundColor: backgroundColorType
          }]
       };

      setChartData(data);
      setData(listMachine);

      setLoadingChart(false)
      setChartReady(true)

    } catch (error) {
      console.log('error', error)
    }
  }

  const getBranch = async () => {
    try {
        const response = await axios.get(`${API_URL}getBranchManager`,
            {
                headers: { 'Authorization': `bearer ${token}` }
            })
        setBranch(response.data);
        if(response.data.length !== 0){
          formBranch.setFieldsValue({
            branch: response.data[0]? response.data[0].id : [],
          });
          setBranchSelected(response.data[0].id)
          fetchData(response.data[0].id, "week")
        }

    } catch (error) {
        console.log(error);
    }
  }

  const changeBranchMachine = async (value) => {
    setLoadingStatus(true)
    setData('')
    setBranchSelected(value)
    fetchData(value, chartType)
  }

  const handleChange = (e) => {
    setChartType(e.target.value)
    
    const dateObj = new Date();2
    const dayMonth     = dateObj.getUTCDate();


    var now = new Date();
    var start = new Date(now.getFullYear(), 0, 0);
    var diff = now - start;
    var oneDay = 1000 * 60 * 60 * 24;
    var dayYear = Math.floor(diff / oneDay);

    if(e.target.value == 'week'){ setCountDate(7);}
    if(e.target.value == '30day'){ setCountDate(30);}
    if(e.target.value == '12month'){ setCountDate(365);}
    if(e.target.value == 'month'){ setCountDate(dayMonth);}
    if(e.target.value == 'year'){ setCountDate(dayYear);}

    fetchData(branchSelected, e.target.value)
  }

  const options = {
    plugins: {
      legend: false,
      datalabels: {
        anchor:'end',
        align:'end',
        color: '#000',
      }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        max: maxValueOfChart
      },
    },
  };

  const columns = [
    {
        title: 'เลขเครื่อง',
        width: 50,
        dataIndex: 'machineNo',
        key: 'machineNo',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text}
          </div>
        ),
    },
    {
      title: 'รหัสเครื่อง',
      width: 100,
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }} onClick={() => controlMachine(record)}>
          { text }
        </div>
      )    
    },
    {
        title: 'ชื่อเครื่อง',
        width: 100,
        dataIndex: 'name',
        key: 'name',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text}
          </div>
        ),
    },
    {
      title: 'ประเภท',
      width: 50,
      dataIndex: 'type',
      key: 'type',
      hidden: true,
      // fixed: 'left',
      render: (text, record) => (
        <div>
          {text === 'washer' ? 'เครื่องซัก' : (text === 'dryer' ? 'เครื่องอบ' : 'เครื่องซักอบ') }
        </div>
      ),
  },
    {
      title: 'ปรเภท',
      width: 50,
      dataIndex: 'type',
      key: 'type',
      // fixed: 'left',
      render: (text, record) => (
        <div>
          {text == 'washer' ? 'เครื่องซัก' : ( text == 'dryer' ? 'เครื่องอบ' : (text == 'washAndDry' ? 'เครื่องซัก/อบ' : text))}
        </div>
      ),
    },
    {
        title: 'จำนวนนาทีใช้งาน',
        width: 100,
        dataIndex: 'usageTime',
        key: 'usageTime',
        render: (text, record) => (
          <div>
             { text }
          </div>
        ),
    },
    {
      title: 'จำนวนนาทีการใช้งานเฉลี่ย ต่อ วัน',
      width: 100,
      dataIndex: 'usageTime',
      key: 'usageTime',
      render: (text, record) => (
        <div>
           { (parseInt(text) / countDate).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </div>
      ),
  },
  ]



  useEffect(() => {
      getBranch()
  }, [])

  return (
    <div className="fetch-event-table">
      <Row>
        <Col span={16}>
          <Form form={formBranch} >
          <Form.Item name="branch" label='สาขา' className='formItemWithNoMargin'>
          <Select style={{width:150}} optionFilterProp="label" onChange={changeBranchMachine}>
              {branch.map(branchItem => (
                  <Select.Option key={branchItem.id} value={branchItem.id} label={branchItem.name}>{branchItem.name}</Select.Option>
              ))}
          </Select>
          </Form.Item>
          </Form>
        </Col>
        {width < 1000 ? <div><br /><br /><br /><br /><br /></div> : '' }
        <Col span={width < 1000 ? 24 : 8}>
          <Radio.Group
          options={[
            { value: 'week', label: '7 วันล่าสุด' },
            { value: '30day', label: '30 วันล่าสุด' },
            { value: '12month', label: '12 เดือนล่าสุด' },
            { value: 'month', label: 'เดือนนี้' },
            { value: 'year', label: 'ปีนี้' },
          ]}              
          onChange={handleChange}
          value={chartType}
          optionType="button"
          buttonStyle="solid"
        />
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <Card title="จำนวนนาทีการใช้งานเครื่องในแต่ละช่วงเวลา" >
              <center> <Spin spinning={loadingChart} /></center>
              {chartReady && <Bar options={options} data={chartData}/> }
          </Card>
        </Col>
      </Row>
      <br /><br />
      {data && data.length > 0 ? (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="id"
          scroll={{
            x: 600,
          }}
        />
      ) : (
        loadingStatus ? <center><Spin spinning={loadingStatus}/></center> : 'ไม่มีข้อมูล'
      )}

    </div>
  )
}

export default ListMachine