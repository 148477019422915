import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Table, Card, Spin, Radio, Form, Select, Anchor} from 'antd'
import { AppContext } from '../App';

import { DownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx'


const IncomeThisWeek = (homeData) => {

    const token = localStorage.getItem('Authentication');
    const API_URL = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({
      current: 1,
      pageSize: Math.floor(window.screen.height/125),
    });

    
    const [loadingChart, setLoadingChart] = useState(true)
    const [chartReady, setChartReady] = useState(false)
    const [chartType, setChartType] = useState("week")
    const [totalIncome, setTotalIncome] = useState(0)
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [onCheckTransaction, setOnCheckTransaction] = useState(false);


    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const [branch, setBranch] = useState([]);
    const [branchSelected, setBranchSelected] = useState('all');

    const [listDateData,setListDateData] = useState([])
    const [listIncomeData,setListIncomeData] = useState([])

    const [form] = Form.useForm();

    // period change
     useEffect(() => {
      
      setChartType(homeData.chartType)
      setChartData({
        labels: [],
        datasets: [],
      })

      FetchListTopSpenderApp(homeData.chartType, branchSelected)

    },[homeData.chartType]);

    // branch change
    useEffect(() => {

      setBranchSelected(homeData.branchSelectedIndex)
      setLoadingChart(true)
      setChartReady(false)
      setChartData({
        labels: [],
        datasets: [],
      })
      FetchListTopSpenderApp(chartType,homeData.branchSelectedIndex)

  }, [homeData.branchSelectedIndex])

    const options = {
        plugins: {
          align: 'center',
          legend: false,
          datalabels: {
            display: true,
            formatter: (val, ctx) => {
              // Grab the label for this value
              const label = ctx.chart.data.labels[ctx.dataIndex];
    
              // Format the number with 2 decimal places
              const formattedVal = Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
              }).format(val);


              const percentVal = (Math.round(((val/totalIncome) * 100 ) * 100) / 100).toFixed(2); 
    
              if(formattedVal != 0){
                return `${label}\n${percentVal}%`;
              }
            },
            color: '#fff',
            backgroundColor: '#404040',
          },
        },
        responsive: true,
    };


   const FetchListTopSpenderApp = async (chartTypeData,branchId) => {

      if(!branchId){branchId = ""}

        try {
            const response = await axios.get(`${API_URL}listTopSpenderApp/${chartTypeData}/${branchId}`, {
              headers: {
                'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })

            const listTopSpender = response.data

            setData(listTopSpender)

          } catch (error) {
            console.log('error', error)
          }
    }

    const columns = [
      {
        title: 'ชื่อลูกค้า',
        width: 100,
        dataIndex: 'customerName',
        key: 'customerName',
        render: (text, record) => (
          <div>
            {text}
          </div>
        )    
      },
      {
        title: 'เบอร์โทร',
        width: 100,
        dataIndex: 'customerPhone',
        key: 'customerPhone',
        render: (text, record) => (
          <div>
            {text}
          </div>
        )    
      },
      {
          title: 'ยอดการจ่าย',
          width: 100,
          dataIndex: 'totalAmount',
          key: 'totalAmount',
          // fixed: 'left',
          render: (text, record) => (
            <div>
              {text}
            </div>
          ),
      }
    ]

    const downloadData = async () =>{

      var incomeRecord;
      const incomeData = []
  
      const headerIncome = {
        customerName: "ชื่อลูกค้า",
        customerPhone: "เบอร์โทร",
        totalAmount: "รายได้",
      }
  
      incomeData.push(headerIncome)
  
      for(let index = 0 ; index < data.length ; index++){
        incomeRecord = {
          customerName: data[index].customerName,
          customerPhone: data[index].customerPhone,
          totalAmount: data[index].totalAmount,
        }
  
        incomeData.push(incomeRecord)
      }
  
      // add width
      var wscols = []
      for(let indexWidth = 0 ; indexWidth < 3 ; indexWidth++){
        wscols.push({wch:20})
      }
  
      const workbook = XLSX.utils.book_new()
      const worksheetIncome = XLSX.utils.json_to_sheet(incomeData, {skipHeader:true})
      worksheetIncome['!cols'] = wscols;
  
  
      XLSX.utils.book_append_sheet(workbook, worksheetIncome, 'รายชื่อผู้ใช้จ่ายสูงสุด')
      XLSX.writeFile(workbook, 'รายชื่อผู้ใช้จ่ายสูงสุด.xlsx')
  
    }

    return (
        <Card title="รายชื่อผู้ใช้จ่ายสูงสุด"  extra={<DownloadOutlined onClick={()=>downloadData()}/>}>
            {data && data.length > 0 ? (
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              rowKey="customerPhone"
            />
          ) : ''}
        </Card>
    )
}

export default IncomeThisWeek