import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Image, Input, Space, Button, message, Tag, Empty} from 'antd'
import { SearchOutlined } from '@ant-design/icons';

import { AppContext } from '../App';

const ListTransaction = () => {
  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])
  const [notEmptyBranch, setNotEmptyBranch] = useState(true) 

  const date = new Date()
    const lastmonth = new Date(date.getFullYear(), date.getMonth() - 1, 1)

    const thisMonthName = date.toLocaleDateString('th-TH', { month: 'long',})
    const lastMonthName = lastmonth.toLocaleDateString('th-TH', { month: 'long',})


  const columns = [
    {
        title: '',
        width: 100,
        dataIndex: 'branchImage',
        key: 'branchImage',
        render: (text, record) => (
          <div>
            <Image
                width={100}
                src={text}
            />
          </div>
        )    
    },
    {
      title: 'ชื่อสาขา',
      width: 100,
      dataIndex: 'branchName',
      key: 'branchName',
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }}>
          {<a href={'/saleReportBranch/'+record.branchCode} >{text}</a>}
        </div>
      )    
    },
    {
        title: 'จำนวนเครื่อง',
        width: 80,
        dataIndex: 'branchMachineCount',
        key: 'branchMachineCount',
        render: (text, record) => (
          <div>
            {text}
          </div>
        ),
    },
    {
      title: thisMonthName,
      width: 90,
      dataIndex: 'totalSaleThisMonth',
      key: 'totalSaleThisMonth',
      render: (text, record) => (
        <div>
          {text ? (text).toLocaleString('en-US') : 0}
        </div>
      ),
    },{
      title: lastMonthName,
      width: 90,
      dataIndex: 'totalSalePrevMonth',
      key: 'totalSalePrevMonth',
      render: (text, record) => (
        <div>
            {text ? (text).toLocaleString('en-US') : 0}
        </div>
      ),
    }
  ]

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}listBranchIncome`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      if(response.data.length === 0){
        setNotEmptyBranch(false)
      }

      const adjustedData = response.data.map((branch) => ({
        ...branch,
        key: branch.branchCode,
      }));


      setData(adjustedData)
    } catch (error) {
      console.log('error', error)
    }
  }


  useEffect(() => {
    if(data.length === 0 && notEmptyBranch){
        fetchData()
    }
  }, [data])

  return (
    <div className="fetch-event-table">
      {notEmptyBranch ? 
      data && data.length > 0 ? (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{
            x: 400,
          }}
          rowKey="branchCode"
        />
      ) : (
        <p>Loading</p>
      )
      :<p>ไม่พบสาขา</p>}
    </div>
  )
}

export default ListTransaction